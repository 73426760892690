import React from "react";
import { Col, Row } from "reactstrap";
import FormInput from "../../../components/common/form/FormInput";

class MessageForm extends React.Component {
  showField() {
    return this.props.isEdit === true ? "hidden" : "";
  }

  disableField() {
    return this.props.isEdit === true ? "disabled" : "";
  }

  render() {
    return (
      <>
        <Row>
          <Col md="4">
            <FormInput
              name="key"
              className="form-control-label"
              id="key"
              placeholder="inputPlaceHolder.message.key"
              type="text"
              disabled={true}
              onChange={this.props.onChange}
              label="inputLabel.message.key"
              value={this.props.valueObj.key || ""}
              errorMsg={this.props.errorObj.key || ""}
            />
          </Col>
          <Col md="4">
            <FormInput
              name="content"
              className="form-control-label"
              id="content"
              placeholder="inputPlaceHolder.message.content"
              type="text"
              onChange={this.props.onChange}
              label="inputLabel.message.content"
              value={this.props.valueObj.content || ""}
              errorMsg={this.props.errorObj.content || ""}
            />
          </Col>
          <Col md="4" sm="6">
            <FormInput
              name="messageType"
              className="form-control-label"
              id="messageType"
              placeholder="inputPlaceHolder.message.messageType"
              type="text"
              disabled={true}
              onChange={this.props.onChange}
              label="inputPlaceHolder.message.messageType"
              value={this.props.valueObj.messageType || ""}
              errorMsg={this.props.errorObj.messageType || ""}
            />
          </Col>
        </Row>
        <Row>
          <Col md="4" sm="6">
            <FormInput
              name="locale"
              className="form-control-label"
              id="locale"
              placeholder="inputPlaceHolder.message.locale"
              type="text"
              disabled={true}
              onChange={this.props.onChange}
              label="inputLabel.message.locale"
              value={this.props.valueObj.locale || ""}
              errorMsg={this.props.errorObj.locale || ""}
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default MessageForm;
