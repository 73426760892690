import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    Card,
    CardBody,
    CardTitle,
    CardImg, CardSubtitle, CardText, Button, Col, Row
} from 'reactstrap';
import UtilService from "../services/utilService";


const controlStyle = {
    backgroundColor: 'black',
    color: 'white',
};

class VideoCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: this.props.items || [], // Initialize from props or empty array
            activeIndex: 0,
            animating: false,
        };
        this.itemsPerSlide = 4; // Adjust the number of items per slide as needed
    }

    next = () => {
        if (this.state.animating) return;
        const nextIndex = this.state.activeIndex === this.getSlides().length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    };

    previous = () => {
        if (this.state.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.getSlides().length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    };

    onExiting = () => {
        this.setState({ animating: true });
    };

    onExited = () => {
        this.setState({ animating: false });
    };

    getSlides() {
        const slides = [];
        const { items } = this.state;
        for (let i = 0; i < items.length; i += this.itemsPerSlide) {
            slides.push(items.slice(i, i + this.itemsPerSlide));
        }
        return slides;
    }

    renderCards(items) {
        let obj = this;
        return items.map((item) => (
            <Card key={UtilService.uuidv4()} style={{ margin: "10px", width: "20%", height: "40%" }}>
                <CardImg
                    style={{ width: '100%', height: '200px', objectFit: 'cover' }}
                    top width="100%" src="https://w7.pngwing.com/pngs/190/593/png-transparent-computer-icons-article-magazine-miscellaneous-angle-text-thumbnail.png" alt="Card image cap" />
                <CardBody>
                    <CardTitle onClick={(e) => {
                        e.preventDefault();
                        obj.props.history.push(
                            `/admin/content/show/${item.uniqueId}`
                        );
                    }}>{item.title}</CardTitle>
                    <CardSubtitle>{item.category}</CardSubtitle>
                </CardBody>
            </Card>
        ));
    }

    render() {
        const { activeIndex } = this.state;
        const slides = this.getSlides().map((group, index) => (
            <CarouselItem
                onExiting={this.onExiting}
                onExited={this.onExited}
                key={UtilService.uuidv4()}
            >
                <div style={{ display: "flex", justifyContent: "space-around" }}>
                    {this.renderCards(group)}
                </div>
            </CarouselItem>
        ));

        return (
            <section>
                <hr />

                <Row>
                    <Col md="12">
                        <div>
                            <br />
                            <h1 style={{ textAlign: "center", marginBottom: "20px", marginTop: "20px" }}>Videos ({this.props.items?.length})</h1>

                            <Carousel style={controlStyle} activeIndex={activeIndex} next={this.next} previous={this.previous}>
                                <CarouselIndicators
                                    items={this.getSlides()}
                                    activeIndex={activeIndex}
                                    onClickHandler={(newIndex) => {
                                        if (this.state.animating) return;
                                        this.setState({ activeIndex: newIndex });
                                    }}
                                />
                                {slides}
                                <CarouselControl className="custom-carousel-control-prev" direction="prev" directionText="Previous" onClickHandler={this.previous} />
                                <CarouselControl className="custom-carousel-control-next" style={controlStyle} direction="next" directionText="Next" onClickHandler={this.next} />
                            </Carousel>
                        </div>
                    </Col>
                </Row>
            </section>
        );
    }
}

export default withRouter(VideoCarousel);
