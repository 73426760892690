import React from "react";
import { Col, Row } from "reactstrap";
import FormInput from "../../../components/common/form/FormInput";
import FormInputSelect from "../../../components/common/form/FormInputSelect";

class AdminForm extends React.Component {
  showField() {
    return this.props.isEdit === true ? "hidden" : "";
  }

  isSuperAdminView() {
    return this.props.isSuperAdminView === true ? "" : "";
  }

  render() {
    let adminRoleList = [];
    adminRoleList.push({ key: "ROLE_ADMIN", value: "ROLE_ADMIN" });
    adminRoleList.push({
      key: "ROLE_TENANT_ADMIN",
      value: "ROLE_TENANT_ADMIN",
    });
    adminRoleList.push({
      key: "ROLE_LEVEL1",
      value: "ROLE_LEVEL1",
    });
    adminRoleList.push({
      key: "ROLE_LEVEL2",
      value: "ROLE_LEVEL2",
    });
    adminRoleList.push({
      key: "ROLE_LEVEL3",
      value: "ROLE_LEVEL3",
    });
    return (
      <>
        <Row>
          <Col md="4">
            <FormInput
              name="firstName"
              className="form-control-label"
              id="firstName"
              placeholder="inputPlaceHolder.admin.firstName"
              type="text"
              onChange={this.props.onChange}
              label="inputLabel.admin.firstName"
              value={this.props.valueObj.firstName || ""}
              errorMsg={this.props.errorObj.firstName || ""}
            />
          </Col>
          <Col md="4">
            <FormInput
              name="middleName"
              className="form-control-label"
              id="middleName"
              placeholder="inputPlaceHolder.admin.middleName"
              type="text"
              onChange={this.props.onChange}
              label="inputLabel.admin.middleName"
              value={this.props.valueObj.middleName || ""}
              errorMsg={this.props.errorObj.middleName || ""}
            />
          </Col>
          <Col md="4">
            <FormInput
              name="lastName"
              className="form-control-label"
              id="lastName"
              placeholder="inputPlaceHolder.admin.lastName"
              type="text"
              onChange={this.props.onChange}
              label="inputLabel.admin.lastName"
              value={this.props.valueObj.lastName || ""}
              errorMsg={this.props.errorObj.lastName || ""}
            />
          </Col>
        </Row>
        <Row>
          <Col md="4" sm="6">
            <FormInput
              name="emailAddress"
              className="form-control-label"
              id="emailAddress"
              placeholder="inputPlaceHolder.admin.emailAddress"
              type="email"
              onChange={this.props.onChange}
              label="inputLabel.admin.emailAddress"
              value={this.props.valueObj.emailAddress || ""}
              errorMsg={this.props.errorObj.emailAddress || ""}
            />
          </Col>
          <Col md="4" sm="6">
            <FormInput
              name="dateOfBirth"
              className="form-control-label"
              id="dateOfBirth"
              placeholder="inputPlaceHolder.admin.dateOfBirth"
              type="date"
              onChange={this.props.onChange}
              label="inputLabel.admin.dateOfBirth"
              value={this.props.valueObj.dateOfBirth || ""}
              errorMsg={this.props.errorObj.dateOfBirth || ""}
            />
          </Col>
          <Col md="4" sm="6">
            <FormInput
              name="password"
              className="form-control-label"
              id="password"
              placeholder="inputPlaceHolder.admin.password"
              type="password"
              onChange={this.props.onChange}
              label="inputLabel.admin.password"
              errorMsg={this.props.errorObj.password || ""}
            />
          </Col>
        </Row>
        <Row>
          <Col md="4" sm="6" className={this.showField()}>
            <FormInput
              name="confirmPassword"
              className="form-control-label"
              id="confirmPassword"
              placeholder="inputPlaceHolder.admin.confirmPassword"
              type="password"
              onChange={this.props.onChange}
              label="inputLabel.admin.confirmPassword"
              errorMsg={this.props.errorObj.confirmPassword || ""}
            />
          </Col>
          <Col md="4" sm="6" className={this.isSuperAdminView()}>
            <FormInputSelect
              name="adminRole"
              className="form-control-label"
              id="adminRole"
              onChange={this.props.onChange}
              label="inputLabel.admin.adminRole"
              value={this.props.valueObj.adminRole || ""}
              errorMsg={this.props.errorObj.adminRole || ""}
              optionList={adminRoleList}
              optionKey="key"
              optionValue="value"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default AdminForm;
