/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {Card, CardBody, CardTitle, Col, Container, Row} from "reactstrap";

class LedgerStats extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <>
                <div className="header bg-info pb-6">
                    <Container fluid>
                        <div className="header-body">
                            <Row>
                                <Col md="6" xl="3">
                                    <Card className="card-stats">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase text-muted mb-0"
                                                    >
                                                        FEES
                                                    </CardTitle>
                                                    <span className="h2 font-weight-bold mb-0">
                            {this.props.fees}
                          </span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div
                                                        className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                                        <i className="ni ni-active-40"/>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <p className="mt-3 mb-0 text-sm">
                        <span className="text-success mr-2">
                          <i className="fa fa-arrow-up"/> 0%
                        </span>{" "}
                                                <span className="text-nowrap">Since last month</span>
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="6" xl="3">
                                    <Card className="card-stats">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase text-muted mb-0"
                                                    >
                                                        SALARY
                                                    </CardTitle>
                                                    <span className="h2 font-weight-bold mb-0">
                            {this.props.salary}
                          </span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div
                                                        className="icon icon-shape bg-gradient-cyan text-white rounded-circle shadow">
                                                        <i className="ni ni-active-40"/>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <p className="mt-3 mb-0 text-sm">
                        <span className="text-success mr-2">
                          <i className="fa fa-arrow-up"/> 0
                        </span>{" "}
                                                <span className="text-nowrap">Since last month</span>
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="6" xl="3">
                                    <Card className="card-stats">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase text-muted mb-0"
                                                    >
                                                        INVOICE
                                                    </CardTitle>
                                                    <span
                                                        className="h2 font-weight-bold mb-0">{this.props.invoice}</span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div
                                                        className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                                        <i className="ni ni-active-40"/>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <p className="mt-3 mb-0 text-sm">
                        <span className="text-success mr-2">
                          <i className="fa fa-arrow-up"/> 0%
                        </span>{" "}
                                                <span className="text-nowrap">Since last month</span>
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="6" xl="3">
                                    <Card className="card-stats">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase text-muted mb-0"
                                                    >
                                                        UN PAID
                                                    </CardTitle>
                                                    <span className="h2 font-weight-bold mb-0">
                            {this.props.unpaid}
                          </span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div
                                                        className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                                                        <i className="ni ni-active-40"/>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <p className="mt-3 mb-0 text-sm">
                        <span className="text-success mr-2">
                          <i className="fa fa-arrow-up"/> 0%
                        </span>{" "}
                                                <span className="text-nowrap">Since last month</span>
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </>
        );
    }
}

export default LedgerStats;
