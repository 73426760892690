import axiosInstance from "../api/api";

const StudentService = {
  list: function () {
    return axiosInstance({
      url: "/api/student/",
      method: "GET",
    });
  },
  fetch: function (uniqueId) {
    return axiosInstance({
      url: `/api/student/${uniqueId}`,
      method: "GET",
    });
  },
  delete: function (uniqueId) {
    return axiosInstance({
      url: `/api/student/${uniqueId}`,
      method: "DELETE",
    });
  },
  create: function (formData) {
    return axiosInstance({
      url: `/api/student/`,
      method: "POST",
      data: formData,
    });
  },
  update: function (formData) {
    return axiosInstance({
      url: `/api/student/`,
      method: "PATCH",
      data: formData,
    });
  },
};
export default StudentService;
