import React, { Component } from 'react';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    Card,
    CardBody,
    CardTitle,
    CardImg, CardSubtitle, CardText, Button, Col, Row
} from 'reactstrap';


const controlStyle = {
    backgroundColor: 'black',
    color: 'white',
};

class CompaniesCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
            animating: false,
        };
        this.itemsPerSlide =4; // Adjust the number of items per slide as needed
        this.items = [
            { id: 1, name: "Company 1" },
            { id: 2, name: "Company 2" },
            { id: 3, name: "Company 3" },
            { id: 4, name: "Company 4" },
            { id: 5, name: "Company 5" },
            { id: 6, name: "Company 6" },
            { id: 7, name: "Company 7" },
        ];
    }

    next = () => {
        if (this.state.animating) return;
        const nextIndex = this.state.activeIndex === this.getSlides().length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    };

    previous = () => {
        if (this.state.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.getSlides().length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    };

    onExiting = () => {
        this.setState({ animating: true });
    };

    onExited = () => {
        this.setState({ animating: false });
    };

    getSlides() {
        const slides = [];
        for (let i = 0; i < this.items.length; i += this.itemsPerSlide) {
            slides.push(this.items.slice(i, i + this.itemsPerSlide));
        }
        return slides;
    }

    renderCards(items) {
        return items.map((item) => (
            <Card key={item.id} style={{ margin: "10px", width: "20%" , height: "40%"}}>
                <CardImg
                    style={{ width: '100%', height: '200px', objectFit: 'cover' }}
                    top width="100%" src="https://plus.unsplash.com/premium_photo-1678903964473-1271ecfb0288?q=80&w=3687&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Card image cap" />
                <CardBody>
                    <CardTitle>{item.name}</CardTitle>
                    <CardSubtitle>Company Details</CardSubtitle>
                </CardBody>
            </Card>
        ));
    }

    render() {
        const { activeIndex } = this.state;
        const slides = this.getSlides().map((group, index) => (
            <CarouselItem
                onExiting={this.onExiting}
                onExited={this.onExited}
                key={index}
            >
                <div style={{ display: "flex", justifyContent: "space-around" }}>
                    {this.renderCards(group)}
                </div>
            </CarouselItem>
        ));

        return (
            <section>
                <hr  />

                <Row>
                    <Col md="12">
                        <div>
                            <br/>
                            <h1 style={{ textAlign: "center", marginBottom: "20px", marginTop: "20px" }}>They have already trusted us... will you?</h1>

                            <Carousel style={controlStyle} activeIndex={activeIndex} next={this.next} previous={this.previous}>
                                <CarouselIndicators
                                    items={this.getSlides()}
                                    activeIndex={activeIndex}
                                    onClickHandler={(newIndex) => {
                                        if (this.state.animating) return;
                                        this.setState({ activeIndex: newIndex });
                                    }}
                                />
                                {slides}
                                <CarouselControl className="custom-carousel-control-prev" direction="prev" directionText="Previous" onClickHandler={this.previous} />
                                <CarouselControl className="custom-carousel-control-next" style={controlStyle} direction="next" directionText="Next" onClickHandler={this.next} />
                            </Carousel>
                        </div>
                    </Col>
                </Row>
            </section>
        );
    }
}

export default CompaniesCarousel;
