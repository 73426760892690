/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {Card, CardBody, CardHeader, Col, Container, FormGroup, Row,} from "reactstrap";
import NotificationService from "../../../services/notificationService";
import _ from "lodash";

class NotificationDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notificationData: {},
            isToolbarHidden: false,
            isSubjectHidden: false,
        };
    }

    componentDidMount() {
        const {uniqueId} = this.props.match.params;
        NotificationService.fetch(uniqueId).then((response) => {
            this.setState({notificationData: response.data});
            this.setState({
                isToolbarHidden: _.includes(
                    ["SMS", "PUSH_WEB", "PUSH_MOBILE"],
                    response.data.notificationType
                ),
                isSubjectHidden: _.includes(
                    ["SMS", "PUSH_MOBILE"],
                    response.data.notificationType
                ),
            });
        });
    }

    render() {
        const {notificationData} = this.state;
        return (
            <>
                <SimpleHeader
                    name="Details"
                    parentName="Notification"
                    heading="ClashMate"
                />
                <Container className="mt--6" fluid>
                    <Row>
                        <Col md="12">
                            <Card className="mb-4">
                                <CardHeader>
                                    <h3 className="mb-0">{notificationData.notificationEvent}</h3>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md="12" className={this.state.isSubjectHidden ? "hidden" : ""}>
                                            <FormGroup>
                                                <label className="form-control-label">Subject</label>
                                                <p>{notificationData.subject}</p>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-control-label">Notification Type</label>
                                                <p>{notificationData.notificationType}</p>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-control-label">Enabled?</label>
                                                <p>{notificationData.isEnabled === true ? "Yes" : "No"}</p>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="4">
                                            <FormGroup>
                                                <label className="form-control-label">Content</label>
                                                <p
                                                    dangerouslySetInnerHTML={{
                                                        __html: notificationData.content,
                                                    }}
                                                ></p>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default NotificationDetails;
