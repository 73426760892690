/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {
  Card,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import ReactBSAlert from "react-bootstrap-sweetalert";
import SummaryService from "../../../services/summaryService";
import _ from "lodash";
import routeVisibleForRole from "../../../util/routeVisibleForRole";
import SummaryDetails from "./Details";

class SummaryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      alert: null
    };
    this.delete = this.delete.bind(this);
    this.notify = this.notify.bind(this);
  }

  componentDidMount() {
    this.populateSummaries();
  };

  populateSummaries = () => {
    SummaryService.list().then((response) => {
      console.log(response);
      this.setState({list: response});
      if(routeVisibleForRole(["ROLE_LEVEL1","ROLE_LEVEL2","ROLE_LEVEL3"])){
        let currentUserId = localStorage.getItem('userId')
        console.log(currentUserId)
        let filteredList = this.filterSummariesByUserId(response, currentUserId)
        this.setState({list: filteredList})
        console.log(this.state.list)
      }
    });
  }

  filterSummariesByUserId = (data, userId) => {
    console.log(data)
    return _.filter(data, summary =>
        _.some(summary.sharedWith, user => user.uniqueId === userId)
    );
  };

  hideDeleteAlert = () => {
    this.setState({
      alert: null
    });
  };

  confirmDeleteAlert = (uniqueId) => {
    this.setState({
      alert: (
          <ReactBSAlert
              warning
              style={{display: "block"}}
              title="Are you sure?"
              onConfirm={() => this.delete(uniqueId)}
              onCancel={this.hideDeleteAlert}
              showCancel
              confirmBtnBsStyle="danger"
              confirmBtnText="Yes, delete it!"
              cancelBtnBsStyle="secondary"
              cancelBtnText="Cancel"
              btnSize=""
          >
            You won't be able to revert this!
          </ReactBSAlert>
      )
    });
  };

  delete(uniqueId) {
    this.setState({alert: null})
    SummaryService.delete(uniqueId).then((response) => {
      console.log(response);
      this.notify(
          response.responseStatus.toLowerCase(),
          response.responseStatus,
          response.message
      );
      //TODO: TO be fixed with redux implementation
      //   this.props.history.push("/admin/dashboard");
      this.populateSummaries();
    });
  }

  notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
          <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
            <span data-notify="message">{message}</span>
          </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  render() {
    let obj = this;
    return (
        <>
          <div className="rna-wrapper">
            <NotificationAlert ref="notificationAlert"/>
          </div>
          <SimpleHeader
              name="List"
              parentName="Summary"
              heading="XareGrwoth"
              newBtnUrl="/admin/meetingReport/list"
              newBtnText="List All Meeting Reports"
          />
          {this.state.alert}
          <Container className="" fluid>
            <Row>
              <Col md="12">
                {this.state.list?.map((summary, index) => (
                    <div key={index}>
                      <SummaryDetails summary={summary} index={index + 1}/>
                    </div>
                ))}
              </Col>
            </Row>
          </Container>
        </>
    );
  }
}

export default SummaryList;
