import React from "react";
import {Col, Row} from "reactstrap";
import FormInput from "../../../components/common/form/FormInput";
import FormReactSelect from "../../../components/common/form/FormReactSelect";

class ContactForm extends React.Component {
    showField() {
        return this.props.isEdit === true ? "hidden" : "";
    }

    render() {
        return (
            <>
                <Row>
                    <Col md="4">
                        <FormInput
                            name="firstName"
                            className="form-control-label"
                            id="firstName"
                            placeholder="inputPlaceHolder.contact.firstName"
                            type="text"
                            onChange={this.props.onChange}
                            label="inputLabel.contact.firstName"
                            value={this.props.valueObj.firstName || ""}
                            errorMsg={this.props.errorObj.firstName || ""}
                        />
                    </Col>
                    <Col md="4">
                        <FormInput
                            name="lastName"
                            className="form-control-label"
                            id="lastName"
                            placeholder="inputPlaceHolder.contact.lastName"
                            type="text"
                            onChange={this.props.onChange}
                            label="inputLabel.contact.lastName"
                            value={this.props.valueObj.lastName || ""}
                            errorMsg={this.props.errorObj.lastName || ""}
                        />
                    </Col>
                    <Col md="4">
                        <FormInput
                            name="title"
                            className="form-control-label"
                            id="title"
                            placeholder="inputPlaceHolder.contact.title"
                            type="text"
                            onChange={this.props.onChange}
                            label="inputLabel.contact.title"
                            value={this.props.valueObj.title || ""}
                            errorMsg={this.props.errorObj.title || ""}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md="4">
                        <FormReactSelect
                            selectKey={this.props.selectKey}
                            isMulti={false}
                            className="form-control-label"
                            isSearchable={true}
                            label="inputLabel.contact.contactRole"
                            name={"contactRole"}
                            onChange={this.props.onContactRoleChange}
                            optionValue={"value"}
                            defaultValue={this.props.selectedContact}
                            optionList={this.props.contactRoles}
                            optionLabel={"key"}
                            placeHolder="inputPlaceHolder.contact.contactRole"
                            errorMsg={this.props.errorObj.contactRole || ""}
                        />
                    </Col>
                    <Col md="4">
                        <FormReactSelect
                            selectKey={this.props.selectKey}
                            isMulti={false}
                            className="form-control-label"
                            isSearchable={true}
                            label="inputLabel.contact.companyId"
                            name={"companyId"}
                            onChange={this.props.onCompanyChange}
                            optionValue={"uniqueId"}
                            defaultValue={this.props.selectedCompany}
                            optionList={this.props.companies}
                            optionLabel={"legalName"}
                            placeHolder="inputPlaceHolder.contact.companyId"
                            errorMsg={this.props.errorObj.companyId || ""}
                        />
                    </Col>
                    <Col md="4">
                        <FormInput
                            name="email"
                            className="form-control-label"
                            id="email"
                            placeholder="Email "
                            type="text"
                            onChange={this.props.onChange}
                            label="Email Address"
                            value={this.props.valueObj.email || ""}
                            errorMsg={this.props.errorObj.email || ""}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md="4">
                        <FormInput
                            name="number"
                            className="form-control-label"
                            id="number"
                            placeholder="Contact Number "
                            type="text"
                            onChange={this.props.onChange}
                            label="Contact Number"
                            value={this.props.valueObj.number || ""}
                            errorMsg={this.props.errorObj.number || ""}
                        />
                    </Col>
                </Row>
            </>
        );
    }
}

export default ContactForm;
