/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// react library for routing
import { Link } from "react-router-dom";
// reactstrap components
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Collapse,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  Container,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Carousel,
  CarouselIndicators,
  CarouselItem,
  CarouselCaption, CarouselControl
} from 'reactstrap';
// core components
import IndexNavbar from "components/Navbars/IndexNavbar.jsx";
import IndexHeader from "components/Headers/IndexHeader.jsx";
import AuthFooter from "components/Footers/AuthFooter.jsx";
import ContentService from "../services/xgContentService";
import _ from "lodash";
import CompaniesCarousel from "./CompaniesCarousel";
import {countries} from "../util/countries";
import FormReactSelect from "../components/common/form/FormReactSelect";
import {services} from "../util/services";
import NotificationAlert from "react-notification-alert";
import {deliveryPeriods} from "../util/deliveryPeriods";
import UtilService from "../services/utilService";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseSearchServices: false,
      collapseSearchArticles: false,
      completeContentList:[],
      searchList:[],
      services : [],
      country :"",
      deliveryPeriod : "",
      selectKey:UtilService.uuidv4()
    };
    this.filterByContentType = this.filterByContentType.bind(this);
    this.handleCountrySelectChange = this.handleCountrySelectChange.bind(this);
    this.submitServices = this.submitServices.bind(this);
    this.notify = this.notify.bind(this);
    this.renderLogin = this.renderLogin.bind(this);
    this.handleDeliveryPeriodChange = this.handleDeliveryPeriodChange.bind(this);
  }

  componentDidMount() {
    ContentService.list().then((response) => {
      console.log(response);
      this.setState({completeContentList: response});
      this.setState({audio: this.filterByContentType(this.state.completeContentList, "AUDIO")});
      this.setState({video: this.filterByContentType(this.state.completeContentList, "VIDEO")});
      this.setState({text: this.filterByContentType(this.state.completeContentList, "TEXT")});
      console.log(this.state)
      this.toggleSearchServices();
    });
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
    console.log(this.state);
  }

  handleCountrySelectChange(valueObj) {
    if (_.isNull(valueObj)) {
      this.setState({selectedCountry: {}, country: ""});
    } else {
      this.setState({selectedCountry: valueObj, country: valueObj.key});
    }
  }

  handleDeliveryPeriodChange(valueObj) {
    if (_.isNull(valueObj)) {
      this.setState({selectedDeliveryPeriod: {}, deliveryPeriod: ""});
    } else {
      this.setState({selectedDeliveryPeriod: valueObj, deliveryPeriod: valueObj.key});
    }
  }

  handleServicesChange = (valueObj, event) => {
    if (_.isNull(valueObj)) {
      this.setState({services: []});
    } else {
      this.setState({services: valueObj});
      const keys = _.map(valueObj, "key")
      this.setState({services: keys});
    }
  };

  submitServices(event) {
    console.log(this.state)
    let userId = localStorage.getItem("userId")
    if(userId === null){
      this.notify("danger", "Error", "You must need to signup before using this feature. Please signup or login.")
    } else {
      if(this.state.services.length === 0|| this.state.country === "" || this.state.deliveryPeriod === ""){
        this.notify("error", "Error", "All fields are mandatory!")
      } else {
        this.notify("success", "Query Submitted", "Thank you for sharing your query with us. We will shortly reply you back.")
        this.setState({services :[], country : "", deliveryPeriod : "",
          selectedCountry: {},selectedDeliveryPeriod: {}, selectKey : UtilService.uuidv4()})
      }
    }
  }

  filterByContentType = (data, type) => {
    return _.filter(data, { contentType: type });
  };

  toggleSearchServices = () => {
    this.setState({
      collapseSearchServices: !this.state.collapseSearchServices,
      collapseSearchArticles: false // Close the articles section when services is toggled
    });
  }

  toggleSearchArticles = (event) => {
    event.preventDefault();
    this.props.history.push("/knowledgeCentre");
  }

  displaySearchArticles = () =>{
    return this.state.searchList.length === 0 ?"DIsplay Search ARticles" : <></>
  }

  onSearchInputChange = (event) => {
    let term = event.target.value;
    this.setState({term:event.target.value})
    console.log(this.state.term)
  }

  displayArticles = () =>{
    if(this.state.completeContentList){
      return (
        <>
          <Container className="mt-6" fluid>
            <hr/>
            <Row className="justify-content-center">
              <h1 className="text-center" style={{ marginBottom: "20px", marginTop: "20px" }}>Recent Posts</h1>
            </Row>
            <Row>
              <Col xl="4">
                <Card>
                  <CardHeader>
                    <h5 className="h3 mb-0">ARTICLES</h5>
                  </CardHeader>

                  <CardBody className="p-0">
                    <ListGroup data-toggle="checklist" flush>
                      {this.state?.text?.map((article, index) => (
                          <ListGroupItem
                              key={index} // Add a key prop for each item in the list
                              className="checklist-entry flex-column align-items-start py-4 px-4"
                          >
                            <div className="checklist-item checklist-item-gray">
                              <div className="checklist-gray-dark">
                                <h5 className="checklist-title mb-0">
                                  {article.title || 'Meeting with teachers.'} {/* Use article title */}
                                </h5>
                                <small>{article.dateCreated || '3:30 PM'}</small> {/* Use article time if available */}
                              </div>
                              <Button color="secondary" size="sm" type="button">
                                <i className="ni ni-button-play float-right" onClick={(e) => {
                                  e.preventDefault();
                                  this.props.history.push(
                                      `/admin/content/show/${article.uniqueId}`
                                  );
                                }}></i>
                              </Button>

                            </div>
                          </ListGroupItem>
                      ))}


                    </ListGroup>

                  </CardBody>
                </Card>
              </Col>
              <Col xl="4">
                <Card>
                  <CardHeader>
                    <h5 className="h3 mb-0">AUDIO</h5>
                  </CardHeader>

                  <CardBody className="p-0">
                    <ListGroup data-toggle="checklist" flush>
                      {this.state?.audio?.map((article, index) => (
                          <ListGroupItem
                              key={index} // Add a key prop for each item in the list
                              className="checklist-entry flex-column align-items-start py-4 px-4"
                          >
                            <div className="checklist-item checklist-item-info">
                              <div className="checklist-info">
                                <h5 className="checklist-title mb-0">
                                  {article.title || 'Meeting with teachers.'} {/* Use article title */}
                                </h5>
                                <small>{article.dateCreated || '3:30 PM'}</small> {/* Use article time if available */}
                              </div>
                              <Button color="secondary" size="sm" type="button" >
                                <i className="ni ni-button-play float-right" onClick={(e) => {
                                  e.preventDefault();
                                  this.props.history.push(
                                      `/admin/content/show/${article.uniqueId}`
                                  );
                                }}></i>
                              </Button>

                            </div>
                          </ListGroupItem>
                      ))}

                    </ListGroup>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="4">
                <Card>
                  <CardHeader>
                    <h5 className="h3 mb-0">VIDEO</h5>
                  </CardHeader>

                  <CardBody className="p-0">
                    <ListGroup data-toggle="checklist" flush>
                      {this.state?.video?.map((article, index) => (
                          <ListGroupItem
                              key={index} // Add a key prop for each item in the list
                              className="checklist-entry flex-column align-items-start py-4 px-4"
                          >
                            <div className="checklist-item checklist-item-info">
                              <div className="checklist-info">
                                <h5 className="checklist-title mb-0">
                                  {article.title || 'Meeting with teachers.'} {/* Use article title */}
                                </h5>
                                <small>{article.dateCreated || '3:30 PM'}</small> {/* Use article time if available */}
                              </div>
                              <Button color="secondary" size="sm" type="button" >
                                <i className="ni ni-button-play float-right" onClick={(e) => {
                                  e.preventDefault();
                                  this.props.history.push(
                                      `/admin/content/show/${article.uniqueId}`
                                  );
                                }}></i>
                              </Button>

                            </div>
                          </ListGroupItem>
                      ))}

                    </ListGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Button color="default" outline
                      onClick={(event) => {
                        event.preventDefault();
                        this.props.history.push("/knowledgeCentre");
                      }}
              >Goto Articles & Videos</Button>
            </Row>
          </Container>
        </>
      );
    }
  }

  renderLogin(){
    return (
        <>
          <Button color="default"  className="mb-3" outline
                  onClick={(event) => {
                    event.preventDefault();
                    this.props.history.push("/auth/login");
                  }}
          >
            Login
          </Button>
        </>
    );
  }

  notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
          <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
            <span data-notify="message">{message}</span>
          </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };



  render() {
    let completeContentList = this.state.completeContentList;
    return (
        <div>
          {/* Navbar */}
          <div className="rna-wrapper">
            <NotificationAlert ref="notificationAlert"/>
          </div>
          <Navbar color="white" light expand="md">
            <NavbarBrand href="/"><img height={"68px"} width={"170px"} src={require("assets/img/brand/xareLogo.png")}/></NavbarBrand>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <Button outline color="default"  className="mb-3"
                        onClick={(event) => {
                          event.preventDefault();
                          this.props.history.push("/knowledgeCentre");
                        }}
                >
                  Knowledge Centre
                </Button>
                <>
                  {localStorage.getItem("userId") ? (
                      <Button
                          color="default"
                          className="mb-3"
                          outline
                          onClick={(event) => {
                            event.preventDefault();
                            this.props.history.push("/admin/dashboard"); // Navigate to CRM if logged in
                          }}
                      >
                        Switch to CRM
                      </Button>
                  ) : (
                      <Button
                          color="default"
                          className="mb-3"
                          outline
                          onClick={(event) => {
                            event.preventDefault();
                            this.props.history.push("/auth/login"); // Navigate to login if not logged in
                          }}
                      >
                        Login
                      </Button>
                  )}
                </>
              </NavItem>
            </Nav>
          </Navbar>

          {/* Hero Banner */}
          <div className="hero-banner">
            <Container fluid>
              <Row>
                <Col>
                  <div className="hero-banner-content">
                    <p>A collaborative approach to </p>
                    <h1>International Expansion</h1>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <br/>
          <br/>
          <br/>
          {/* Collapsible Sections */}
          <Container className={"mt-10"}>
            {/* Search Services */}
            <div className="text-center">
            <Button color="default" onClick={this.toggleSearchServices} className="mb-3">
              Search Services
            </Button>
            {/* Search Articles */}
            <Button color="grey" onClick={this.toggleSearchArticles} className="mb-3">
              Search Articles
            </Button>
            </div>
            <Collapse isOpen={this.state.collapseSearchServices}>
              <Form>
                <Row form>
                  <Col md={12}>
                    <FormGroup>
                      <FormReactSelect
                          selectKey={this.state.selectKey}
                          isMulti={true}
                          className="form-control-label"
                          isSearchable={true}
                          label="Select Services"
                          name={"services"}
                          onChange={this.handleServicesChange}
                          optionValue={"value"}
                          defaultValue={this.state.selectedServices}
                          optionList={services}
                          optionLabel={"key"}
                          placeHolder="Select Services"
                          errorMsg={this.state?.errorObj?.country || ""}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col md={6}>
                    <FormGroup>
                      <FormReactSelect
                          selectKey={this.state.selectKey}
                          isMulti={false}
                          className="form-control-label"
                          isSearchable={true}
                          label="inputLabel.company.country"
                          name={"country"}
                          onChange={this.handleCountrySelectChange}
                          optionValue={"value"}
                          defaultValue={this.state.selectedCountry}
                          optionList={countries}
                          optionLabel={"key"}
                          placeHolder="inputPlaceHolder.company.country"
                          errorMsg={this.state?.errorObj?.country || ""}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <FormReactSelect
                          selectKey={this.state.selectKey}
                          isMulti={false}
                          className="form-control-label"
                          isSearchable={true}
                          label="Delivery Period"
                          name={"deliveryPeriod"}
                          onChange={this.handleDeliveryPeriodChange}
                          optionValue={"value"}
                          defaultValue={this.state.selectedDeliveryPeriod}
                          optionList={deliveryPeriods}
                          optionLabel={"key"}
                          placeHolder="Select Delivery Period"
                          errorMsg={this.state?.errorObj?.deliveryPeriod || ""}
                      />
                    </FormGroup>
                  </Col>

                </Row>

                <div className="text-center">
                  <Button color="danger" outline type="button" onClick={this.submitServices}>Submit</Button>
                </div>
              </Form>
            </Collapse>


            <Collapse isOpen={this.state.collapseSearchArticles}>
              <Form>
                <FormGroup>
                  <Label for="searchText">Enter Search Term</Label>
                  <Input onChange={this.onSearchInputChange} type="text" name="searchText" id="searchText" />
                </FormGroup>
                <div className="text-center">
                  <Button color="danger" onClick={this.onSearchInputChange} type="button">Submit</Button>
                </div>
              </Form>
            </Collapse>
          </Container>


          {this.displayArticles()}
          <CompaniesCarousel/>

          <footer className="py-5" id="footer-main">
            <Container>
              <Row className="align-items-center justify-content-xl-between">
                <Col xl="6">
                  <div className="copyright text-center text-xl-left text-muted">
                    © {new Date().getFullYear()}{" "}
                    <a
                        className="font-weight-bold ml-1"
                        href="https://sekim-international.com/"
                        target="_blank"
                    >
                      Sekim International
                    </a>
                  </div>
                </Col>
                <Col xl="6">
                  <Nav className="nav-footer justify-content-center justify-content-xl-end">
                    <NavItem>
                      <NavLink
                          href="https://xaregrowth.com"
                          target="_blank"
                      >
                        XareGrowth
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                          href=""
                          target="_blank"
                      >
                        About Us
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                          href=""
                          target="_blank"
                      >
                        Blog
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                          href=""
                          target="_blank"
                      >
                        License
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
              </Row>
            </Container>
          </footer>
        </div>
    );
  }
}

export default Index;
