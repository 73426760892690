import React from "react";
import { Col, Row } from "reactstrap";
import FormInput from "../../../components/common/form/FormInput";
import FormReactSelect from "../../../components/common/form/FormReactSelect";
import {highMediumLow} from "../../../util/highMediumLow";
import UtilService from "../../../services/utilService";


class AttachCompanyForm extends React.Component {
    showField() {
        return this.props.isEdit === true ? "hidden" : "";
    }

    render() {
        return (
            <>
                <Row>
                    <Col md="4">
                        <FormReactSelect
                            selectKey={this.props.selectKey}
                            isMulti={false}
                            className="form-control-label"
                            isSearchable={true}
                            label="Company"
                            name={"companyUID"}
                            onChange={this.props.onCompanyChange}
                            optionValue={"uniqueId"}
                            defaultValue={this.props.selectedCompany}
                            optionList={this.props.companies}
                            optionLabel={"legalName"}
                            placeHolder="Select Company"
                            errorMsg={this.props.errorObj.companyUID || ""}
                        />
                    </Col>
                    <Col md="4">
                        <FormReactSelect
                            selectKey={UtilService.uuidv4()}
                            isMulti={false}
                            className="form-control-label"
                            isSearchable={true}
                            label="Potential"
                            name={"potential"}
                            onChange={this.props?.handlePotentialChange}
                            optionValue={"value"}
                            defaultValue={this.props?.selectedPotential}
                            optionList={highMediumLow}
                            optionLabel={"key"}
                            placeHolder="Select Potential"
                            errorMsg={this.props?.errorObj?.potential || ""}
                        />
                    </Col>
                    <Col md="4">
                        <FormReactSelect
                            selectKey={UtilService.uuidv4()}
                            isMulti={false}
                            className="form-control-label"
                            isSearchable={true}
                            label="Interest"
                            name={"interest"}
                            onChange={this.props?.handleInterestChange}
                            optionValue={"value"}
                            defaultValue={this.props?.selectedInterest}
                            optionList={highMediumLow}
                            optionLabel={"key"}
                            placeHolder="Select Interest"
                            errorMsg={this.props?.errorObj?.interest || ""}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md="4" sm="6">
                        <FormReactSelect
                            selectKey={UtilService.uuidv4()}
                            isMulti={false}
                            className="form-control-label"
                            isSearchable={true}
                            label="Priority"
                            name={"priority"}
                            onChange={this.props?.handlePriorityChange}
                            optionValue={"value"}
                            defaultValue={this.props?.selectedPriority}
                            optionList={highMediumLow}
                            optionLabel={"key"}
                            placeHolder="Select Priority"
                            errorMsg={this.props?.errorObj?.priority || ""}
                        />
                    </Col>
                    <Col md="4" sm="6">
                        <FormInput
                            name="lastContact"
                            className="form-control-label"
                            id="lastContact"
                            placeholder="inputPlaceHolder.admin.lastContact"
                            type="date"
                            onChange={this.props.onChange}
                            label="Date of Last Contact"
                            value={this.props?.valueObj?.lastContact || ""}
                            errorMsg={this.props?.errorObj?.lastContact || ""}
                        />
                    </Col>
                    <Col md="4" sm="6">
                        <FormInput
                            name="nextContact"
                            className="form-control-label"
                            id="nextContact"
                            placeholder="inputPlaceHolder.admin.nextContact"
                            type="date"
                            onChange={this.props.onChange}
                            label="Date of Next Contact"
                            value={this.props.valueObj?.nextContact || ""}
                            errorMsg={this.props.errorObj?.nextContact || ""}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md="4" sm="6" >
                        <FormReactSelect
                            selectKey={this.props.contactKey}
                            isMulti={false}
                            className="form-control-label"
                            isSearchable={true}
                            label="Key Contact"
                            name={"mainContact"}
                            onChange={this.props.handleMainContactChange}
                            optionValue={"uniqueId"}
                            defaultValue={this.props.selectedMainContact}
                            optionList={this.props.contacts}
                            optionLabel={"fullName"}
                            placeHolder="Select Key Contact"
                            errorMsg={this.props.errorObj.mainContact || ""}
                        />
                    </Col>
                </Row>
            </>
        );
    }
}

export default AttachCompanyForm;
