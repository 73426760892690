import axiosInstance from "../api/api";

const MessageService = {
  list: function () {
    return axiosInstance({
      url: "/api/message/",
      method: "GET",
    });
  },
  fetch: function (uniqueId) {
    return axiosInstance({
      url: `/api/message/${uniqueId}`,
      method: "GET",
    });
  },
  create: function (formData) {
    return axiosInstance({
      url: `/api/message/`,
      method: "POST",
      data: formData,
    });
  },
  update: function (formData) {
    return axiosInstance({
      url: `/api/message/`,
      method: "PATCH",
      data: formData,
    });
  },
};
export default MessageService;
