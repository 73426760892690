/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/pages/dashboards/AdminDashboard.jsx";
import AdminList from "views/pages/admin/List.jsx";
import AdminDetails from "views/pages/admin/Details.jsx";
import AdminCreate from "views/pages/admin/Create.jsx";
import AdminEdit from "../views/pages/admin/Edit";
import ParentDetails from "../views/pages/parent/Details";
import CollapsableMessageList from "../views/pages/message/CollapseableMessageList";
import MessageCreate from "../views/pages/message/Create";
import MessageEdit from "../views/pages/message/Edit";
import MessageDetails from "../views/pages/message/Details";
import LeadList from "../views/pages/lead/List";
import LeadDetails from "../views/pages/lead/Details";
import LeadEdit from "../views/pages/lead/Edit";
import LeadCreate from "../views/pages/lead/Create";
import LedgerList from "../views/pages/finance/admin/LedgerList";
import LedgerCreate from "../views/pages/finance/admin/LedgerCreate";
import LedgerDetailsAdmin from "../views/pages/finance/admin/LedgerDetailsAdmin";
import TenantSettingList from "../views/pages/tenantSetting/List";
import AdminSettings from "../views/pages/settings/AdminSettings";
import CreateNotice from "../views/pages/social/noticeboard/CreateNotice";
import Notices from "../views/pages/social/noticeboard/Notices";
import NoticeDetails from "../views/pages/social/noticeboard/NoticeDetails";
import EditNotice from "../views/pages/social/noticeboard/EditNotice";
import NotificationList from "../views/pages/notification/List";
import NotificationDetail from "../views/pages/notification/Details";
import NotificationEdit from "../views/pages/notification/Edit";
import NotificationQueueList from "../views/pages/notification/queue/List";
import NotificationQueueDetails from "../views/pages/notification/queue/Details";
import CompanyList from "../views/pages/company/List";
import CompanyCreate from "../views/pages/company/Create";
import CompanyEdit from "../views/pages/company/Edit";
import ContentList from "../views/pages/content/List";
import ContentDetails from "../views/pages/content/Details";
import ContentEdit from "../views/pages/content/Edit";
import ContentCreate from "../views/pages/content/Create";
import ContactCreate from "../views/pages/contact/Create";
import ContactList from "../views/pages/contact/List";
import ContactDetails from "../views/pages/contact/Details";
import ContactEdit from "../views/pages/contact/Edit";
import MeetingReport from "../views/pages/meetingReport/MeetingReport";
import ReportList from "../views/pages/meetingReport/ReportList";
import MeetingList from "../views/pages/report/List";
import MeetingDetails from "../views/pages/report/Details";
import MeetingEdit from "../views/pages/report/Edit";
import MeetingCreate from "../views/pages/report/Create";
import ProjectList from "../views/pages/project/List";
import ProjectCreate from "../views/pages/project/Create";
import ProjectDetails from "../views/pages/project/Details";
import CompanyDetails from "../views/pages/company/Details";
import utilService from "../services/utilService";
import HelperUtil from "../util/appUtil";
import routeVisibleForRole from "../util/routeVisibleForRole";
import NextStepsList from "../views/pages/nextSteps/List";
import NextStepsComplete from "../views/pages/nextSteps/NextStepsComplete";
import ReportListByCompany from "../views/pages/report/ReportListByCompany";
import SummaryList from "../views/pages/summary/List";

const adminRoutes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: "ni ni-shop text-primary",
        component: Dashboard,
        layout: "/admin",
    },
    {
        path: "/list",
        name: "Users",
        icon: "ni ni-shop text-primary",
        component: AdminList,
        layout: "/admin",
        invisible: !routeVisibleForRole(["ROLE_TENANT_ADMIN","ROLE_ADMIN"]),
    },
    {
        path: "/show/:uniqueId",
        name: "Admin Details",
        icon: "ni ni-shop text-primary",
        component: AdminDetails,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/create",
        name: "Create Admin",
        icon: "ni ni-shop text-primary",
        component: AdminCreate,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/edit/:uniqueId",
        name: "Edit Admin",
        icon: "ni ni-shop text-primary",
        component: AdminEdit,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/company/list",
        name: "Company",
        icon: "ni ni-shop text-primary",
        component: CompanyList,
        layout: "/admin",
    },
    {
        path: "/company/show/:uniqueId",
        name: "Company Details",
        icon: "ni ni-shop text-primary",
        component: CompanyDetails,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/company/edit/:uniqueId",
        name: "Company Edit",
        icon: "ni ni-shop text-primary",
        component: CompanyEdit,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/company/create",
        name: "Company Create",
        icon: "ni ni-shop text-primary",
        component: CompanyCreate,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/content/list",
        name: "Content",
        icon: "ni ni-shop text-primary",
        component: ContentList,
        layout: "/admin",
    },
    {
        path: "/content/show/:uniqueId",
        name: "Content Details",
        icon: "ni ni-shop text-primary",
        component: ContentDetails,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/content/edit/:uniqueId",
        name: "Content Edit",
        icon: "ni ni-shop text-primary",
        component: ContentEdit,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/content/create",
        name: "Content Create",
        icon: "ni ni-shop text-primary",
        component: ContentCreate,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/contact/list",
        name: "Contact",
        icon: "ni ni-shop text-primary",
        component: ContactList,
        layout: "/admin",
    },
    {
        path: "/contact/show/:uniqueId",
        name: "Contact Details",
        icon: "ni ni-shop text-primary",
        component: ContactDetails,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/contact/edit/:uniqueId",
        name: "Contact Edit",
        icon: "ni ni-shop text-primary",
        component: ContactEdit,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/contact/create",
        name: "Contact Create",
        icon: "ni ni-shop text-primary",
        component: ContactCreate,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/project/listOld/",
        name: "Project Events",
        icon: "ni ni-shop text-primary",
        component: ReportList,
        layout: "/admin",
        invisible: false,
    },
    {
        path: "/message/list",
        name: "Messages",
        icon: "ni ni-shop text-primary",
        component: CollapsableMessageList,
        layout: "/admin",
        invisible: true
    },
    {
        path: "/message/show/:uniqueId",
        name: "Message Details",
        icon: "ni ni-shop text-primary",
        component: MessageDetails,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/message/edit/:uniqueId",
        name: "Message Edit",
        icon: "ni ni-shop text-primary",
        component: MessageEdit,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/message/create",
        name: "Message Create",
        icon: "ni ni-shop text-primary",
        component: MessageCreate,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/lead/list",
        name: "Leads",
        icon: "ni ni-shop text-primary",
        component: LeadList,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/lead/show/:uniqueId",
        name: "Section Details",
        icon: "ni ni-shop text-primary",
        component: LeadDetails,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/lead/edit/:uniqueId",
        name: "Section Edit",
        icon: "ni ni-shop text-primary",
        component: LeadEdit,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/lead/create",
        name: "Section Create",
        icon: "ni ni-shop text-primary",
        component: LeadCreate,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/ledger/list",
        name: "Ledger",
        icon: "ni ni-shop text-primary",
        component: LedgerList,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/ledger/show/:uniqueId",
        name: "Ledger",
        icon: "ni ni-shop text-primary",
        component: LedgerDetailsAdmin,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/ledger/create",
        name: "Ledger",
        icon: "ni ni-shop text-primary",
        component: LedgerCreate,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/tenantSetting/list",
        name: "Tenant Setting",
        icon: "ni ni-shop text-primary",
        component: TenantSettingList,
        layout: "/admin",
        invisible: true
    },
    {
        path: "/settings",
        name: "Ledger",
        icon: "ni ni-shop text-primary",
        component: AdminSettings,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/notice/create/",
        name: "Create Notice",
        icon: "ni ni-shop text-primary",
        component: CreateNotice,
        layout: "/admin",
        invisible: true,
    },
    /*{
        path: "/notice/create/",
        name: "Create Notice",
        icon: "ni ni-shop text-primary",
        component: CreateNotice,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/notice/",
        name: "Notices",
        icon: "ni ni-shop text-primary",
        component: Notices,
        layout: "/admin",
        invisible: false,
    },
    {
        path: "/notices/show/:uniqueId",
        name: "View notice",
        icon: "ni ni-shop text-primary",
        component: NoticeDetails,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/notices/edit/:uniqueId",
        name: "Edit Notice",
        icon: "ni ni-shop text-primary",
        component: EditNotice,
        layout: "/admin",
        invisible: true,
    },*/
    {
        path: "/notification/list",
        name: "Notification List",
        icon: "ni ni-shop text-primary",
        component: NotificationList,
        layout: "/admin",
        invisible: true
    },
    {
        path: "/notification/show/:uniqueId",
        name: "Notification",
        icon: "ni ni-shop text-primary",
        component: NotificationDetail,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/notification/edit/:uniqueId",
        name: "Notification",
        icon: "ni ni-shop text-primary",
        component: NotificationEdit,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/notificationQueue/list",
        name: "Notification Queue",
        icon: "ni ni-shop text-primary",
        component: NotificationQueueList,
        layout: "/admin",
        invisible: true
    },
    {
        path: "/notificationQueue/show/:uniqueId",
        name: "Notification Queue",
        icon: "ni ni-shop text-primary",
        component: NotificationQueueDetails,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/meetingReport/list",
        name: "Meeting List",
        icon: "ni ni-shop text-primary",
        component: MeetingList,
        layout: "/admin",
        invisible: false
    },
    {
        path: "/meetingReport/show/:uniqueId",
        name: "Meeting",
        icon: "ni ni-shop text-primary",
        component: MeetingDetails,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/meetingReport/company/:uniqueId",
        name: "Meeting",
        icon: "ni ni-shop text-primary",
        component: ReportListByCompany,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/meetingReport/create",
        name: "Meeting",
        icon: "ni ni-shop text-primary",
        component: MeetingCreate,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/meetingReport/edit/:uniqueId",
        name: "Meeting",
        icon: "ni ni-shop text-primary",
        component: MeetingEdit,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/project/list",
        name: "Project List",
        icon: "ni ni-shop text-primary",
        component: ProjectList,
        layout: "/admin",
        invisible: false
    },
    {
        path: "/project/create",
        name: "Project Create",
        icon: "ni ni-shop text-primary",
        component: ProjectCreate,
        layout: "/admin",
        invisible: true
    },
    {
        path: "/project/show/:uniqueId",
        name: "Project Show",
        icon: "ni ni-shop text-primary",
        component: ProjectDetails,
        layout: "/admin",
        invisible: true
    },
    {
        path: "/nextSteps/list/",
        name: "Tasks",
        icon: "ni ni-shop text-primary",
        component: NextStepsComplete,
        layout: "/admin",
        invisible: false
    },
    {
        path: "/summary",
        name: "Summaries",
        icon: "ni ni-shop text-primary",
        component: SummaryList,
        layout: "/admin",
        invisible: false
    },




];

export default adminRoutes;
