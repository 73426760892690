import axiosInstance from "../api/api";

const ProjectCompanyService = {
    list: function () {
        return axiosInstance({
            url: "/api/projectCompany/",
            method: "GET",
        });
    },
    fetch: function (uniqueId) {
        return axiosInstance({
            url: `/api/projectCompany/${uniqueId}`,
            method: "GET",
        });
    },
    delete: function (uniqueId) {
        return axiosInstance({
            url: `/api/projectCompany/${uniqueId}`,
            method: "DELETE",
        });
    },
    create: function (formData) {
        return axiosInstance({
            url: `/api/projectCompany/`,
            method: "POST",
            data: formData,
        });
    },
    update: function (formData) {
        return axiosInstance({
            url: `/api/projectCompany/`,
            method: "PATCH",
            data: formData,
        });
    },
};
export default ProjectCompanyService;
