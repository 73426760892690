import React, { Component } from 'react';
import Summary from './Summary';
import SimpleHeader from "../../../components/Headers/SimpleHeader";
import {Button, Card, CardBody, CardHeader, Col, Container, Form, Row} from "reactstrap";
import AdminForm from "../admin/Form";
import AdminService from "../../../services/adminService";
import FormInput from "../../../components/common/form/FormInput";
import ContactForm from "../contact/Form";
import {contactRole} from "../../../util/contactRole";
import FormReactSelect from "../../../components/common/form/FormReactSelect";
import UtilService from "../../../services/utilService";
import CompanyService from "../../../services/companyService";
import _ from "lodash";
import ContactService from "../../../services/contactService"; // Import the Summary component
import {meetingType} from "../../../util/meetingType";

class MeetingReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: '',
            companyName: '',
            eventType: '',
            attendees: [],
            summaries: [{ text: '', sharedWith: '' }],
            companySuggestions: [],
            companyList: [], // This should be populated with company data from your database
            contacts: [], // This should be populated based on the selected company
            companySelectKey: UtilService.uuidv4(),
            attendeesSelectKey: UtilService.uuidv4(),
            attendesForSelection : [],
            errorData: {},
            allContacts : [],
            selectedEventType:{},
            selectedAttendees: []
        };
    }

    componentDidMount() {
        CompanyService.list().then((response) => {
            console.log(response);
            this.setState({companyList: response, companySelectKey :UtilService.uuidv4() });
        });
        ContactService.list().then((response) => {
            console.log(response);
            this.setState({allContacts: response, attendeesSelectKey :UtilService.uuidv4() });
        });


        console.log("Component did mount")
        console.log(this.state)
    }

    handleDateChange = (event) => {
        this.setState({ date: event.target.value });
    };

    handleCompanyChange = (valueObj, event) => {
        console.log(valueObj);
        if (_.isNull(valueObj)) {
            this.setState({selectedCompany: {}, companyId :"", attendees : []});
        } else {
            const filteredContacts = _.filter(this.state.allContacts, (contact) => contact.company.uniqueId === valueObj.uniqueId);
            this.setState({selectedCompany: valueObj,
                companyId : valueObj.uniqueId,
                attendesForSelection: filteredContacts,
                attendees : [],
                selectedAttendees : [],
                attendeesSelectKey: UtilService.uuidv4()
            });
        }
        this.setState({ companyName: valueObj.legalName });

    };


    handleEventTypeChange = (valueObj,event) => {
            if (_.isNull(valueObj)) {
                this.setState({selectedEventType : {}, eventType:  ""});
            } else {
                this.setState({ selectedEventType : valueObj, eventType: valueObj.key});
            }
        }

    handleAttendeesChange = (valueObj,event) => {
            if (_.isNull(valueObj)) {
                this.setState({selectedAttendees: []});
            } else {
                this.setState({selectedAttendees: valueObj});
                const attendees = _.map(valueObj, "uniqueId")
                this.setState({attendees: attendees});
            }
    };

    handleSummaryTextChange = (index, value) => {
        const { summaries } = this.state;
        summaries[index].text = value;
        this.setState({ summaries });
    };

    handleSharedWithChange = (index, value) => {
        const { summaries } = this.state;
        summaries[index].sharedWith = value;
        this.setState({ summaries });
    };

    addSummary = () => {
        this.setState((prevState) => ({
            summaries: [...prevState.summaries, { text: '', sharedWith: '' }],
        }));
    };

    handleSubmit(event) {
        event.preventDefault();
        console.log(this.state)
    }


    render() {
        const { date, companyName, eventType, attendees, summaries, companySuggestions, contacts } = this.state;

                return (
                    <>
                        <>
                            <SimpleHeader
                                name="Create"
                                parentName="Meeting Report"
                                heading="XareGrowth"
                                // newBtnUrl="/admin/contact/list"
                                // newBtnText="List Contacts"
                            />
                            <Container className="mt--6" fluid>
                                <Card className="mb-4">
                                    <CardHeader>
                                        <h3 className="mb-0">Create Meeting Report</h3>
                                        <div className="text-center">
                                            <Button className="my-4" color="info" type="submit">
                                                Submit Report
                                            </Button>
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        <Form role="form" onSubmit={this.handleSubmit}>

                                            <Row>
                                                <Col md="4" sm="6">
                                                <FormInput
                                                        name="date"
                                                        className="form-control-label"
                                                        id="date"
                                                        placeholder="inputPlaceHolder.meetingReport.date"
                                                        type="date"
                                                        onChange={this.handleDateChange}
                                                        label="inputLabel.meetingReport.date"
                                                        value={this.state.date || ""}
                                                        // errorMsg={this.errorObj.date || ""}
                                                    />
                                                </Col>
                                                <Col md="4" sm="6">
                                                    <FormReactSelect
                                                        selectKey={this.state.companySelectKey}
                                                        isMulti={false}
                                                        className="form-control-label"
                                                        isSearchable={true}
                                                        label="inputLabel.meetingReport.company"
                                                        name={"company"}
                                                        onChange={this.handleCompanyChange}
                                                        optionValue={"uniqueId"}
                                                        defaultValue={this.selectedCompany}
                                                        optionList={this.state.companyList}
                                                        optionLabel={"legalName"}
                                                        placeHolder="inputPlaceHolder.meetingReport.company"
                                                        errorMsg={this.state.errorData.companyId || ""}
                                                    />
                                                </Col>
                                                <Col md="4" sm="6">
                                                    <FormReactSelect
                                                        selectKey={this.state.attendeesSelectkey}
                                                        isMulti={true}
                                                        className="form-control-label"
                                                        isSearchable={true}
                                                        label="inputLabel.meetingReport.attendees"
                                                        name={"attendees"}
                                                        onChange={this.handleAttendeesChange}
                                                        optionValue={"uniqueId"}
                                                        defaultValue={this.selectedAttendees}
                                                        optionList={this.state.attendesForSelection}
                                                        optionLabel={"fullName"}
                                                        placeHolder="inputPlaceHolder.meetingReport.attendees"
                                                        errorMsg={this.state.errorData.companyId || ""}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4} sm={6}>
                                                    <FormReactSelect
                                                        selectKey={UtilService.uuidv4()}
                                                        isMulti={false}
                                                        className="form-control-label"
                                                        isSearchable={true}
                                                        label="inputLabel.meetingReport.eventType"
                                                        name={"eventType"}
                                                        onChange={this.handleEventTypeChange}
                                                        optionValue={"value"}
                                                        defaultValue={this.state.selectedEventType}
                                                        optionList={meetingType}
                                                        optionLabel={"key"}
                                                        placeHolder="inputPlaceHolder.meetingReport.eventType"
                                                        errorMsg={this.state.errorData.eventType || ""}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                {summaries.map((summary, index) => (
                                                    <Summary
                                                        key={index}
                                                        index={index}
                                                        text={summary.text}
                                                        sharedWith={summary.sharedWith}
                                                        onTextChange={this.handleSummaryTextChange}
                                                        onSharedWithChange={this.handleSharedWithChange}
                                                    />
                                                ))}
                                            </Row>
                                            <Row>
                                                <Col md={4} sm={6}>
                                                    <Button onClick={this.addSummary} className="my-4" color="info" type="button">
                                                        Add new summary
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Container>
                            <div>
                                <h1>Create Meeting Report</h1>

                                <label>Date:</label>
                                <input
                                    type="date"
                                    value={date}
                                    onChange={this.handleDateChange}
                                />

                                <label>Company Name:</label>
                                <input
                                    type="text"
                                    value={companyName}
                                    onChange={this.handleCompanyChange}
                                    placeholder="Search for company..."
                                />
                                {companySuggestions.length > 0 && (
                                    <ul>
                                        {companySuggestions.map((company, index) => (
                                            <li key={index} onClick={() => this.setState({companyName: company.name})}>
                                                {company.name}
                                            </li>
                                        ))}
                                    </ul>
                                )}

                                <label>Type of Event:</label>
                                <select value={eventType} onChange={this.handleEventTypeChange}>
                                    <option value="Call">Call</option>
                                    <option value="Web-meeting">Web-meeting</option>
                                    <option value="Face-to-face">Face-to-face</option>
                                </select>

                                <label>Attendees:</label>
                                <select multiple value={attendees} onChange={this.handleAttendeesChange}>
                                    {contacts.map((contact, index) => (
                                        <option key={index} value={contact.name}>
                                            {contact.name}
                                        </option>
                                    ))}
                                </select>

                                {summaries.map((summary, index) => (
                                    <Summary
                                        key={index}
                                        index={index}
                                        text={summary.text}
                                        sharedWith={summary.sharedWith}
                                        onTextChange={this.handleSummaryTextChange}
                                        onSharedWithChange={this.handleSharedWithChange}
                                    />
                                ))}

                                <button onClick={this.addSummary}>Add another Summary</button>
                            </div>
                        </>
                    </>

                );
    }
}

export default MeetingReport;
