import React from "react";
import {Col, Row} from "reactstrap";
import FormInput from "../../../components/common/form/FormInput";
import FormReactSelect from "../../../components/common/form/FormReactSelect";

class LeadForm extends React.Component {
    render() {
        return (
            <>
                <Row>
                    <Col md="4">
                        <FormInput
                            name="firstName"
                            className="form-control-label"
                            id="firstName"
                            placeholder="inputPlaceHolder.lead.firstName"
                            type="text"
                            onChange={this.props.onChange}
                            label="inputLabel.lead.firstName"
                            value={this.props.valueObj.firstName || ""}
                            errorMsg={this.props.errorObj.firstName || ""}
                        />
                    </Col>
                    <Col md="4">
                        <FormInput
                            name="middleName"
                            className="form-control-label"
                            id="middleName"
                            placeholder="inputPlaceHolder.lead.middleName"
                            type="text"
                            onChange={this.props.onChange}
                            label="inputLabel.lead.middleName"
                            value={this.props.valueObj.middleName || ""}
                            errorMsg={this.props.errorObj.middleName || ""}
                        />
                    </Col>
                    <Col md="4">
                        <FormInput
                            name="lastName"
                            className="form-control-label"
                            id="lastName"
                            placeholder="inputPlaceHolder.lead.lastName"
                            type="text"
                            onChange={this.props.onChange}
                            label="inputLabel.lead.lastName"
                            value={this.props.valueObj.lastName || ""}
                            errorMsg={this.props.errorObj.lastName || ""}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md="4">
                        <FormInput
                            name="emailAddress"
                            className="form-control-label"
                            id="emailAddress"
                            placeholder="inputPlaceHolder.lead.emailAddress"
                            type="text"
                            onChange={this.props.onChange}
                            label="inputLabel.lead.emailAddress"
                            value={this.props.valueObj.emailAddress || ""}
                            errorMsg={this.props.errorObj.emailAddress || ""}
                        />
                    </Col>
                    <Col md="4">
                        <FormInput
                            name="phoneNumber"
                            className="form-control-label"
                            id="phoneNumber"
                            placeholder="inputPlaceHolder.lead.phoneNumber"
                            type="number"
                            onChange={this.props.onChange}
                            label="inputLabel.lead.phoneNumber"
                            value={this.props.valueObj.phoneNumber || ""}
                            errorMsg={this.props.errorObj.phoneNumber || ""}
                        /></Col>
                    <Col md="4">
                        <FormInput
                            name="guardianName"
                            className="form-control-label"
                            id="guardianName"
                            placeholder="inputPlaceHolder.lead.guardianName"
                            type="text"
                            onChange={this.props.onChange}
                            label="inputLabel.lead.guardianName"
                            value={this.props.valueObj.guardianName || ""}
                            errorMsg={this.props.errorObj.guardianName || ""}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md="4">
                        <FormReactSelect
                            selectKey={this.props.selectKey}
                            isMulti={false}
                            className="form-control-label"
                            isSearchable={true}
                            label="inputLabel.lead.leadType"
                            name={"leadType"}
                            onChange={this.props.onSelectChange}
                            optionValue={"value"}
                            defaultValue={this.props.selectedLead}
                            optionList={this.props.leadTypes}
                            optionLabel={"key"}
                            placeHolder="inputPlaceHolder.lead.leadType"
                            errorMsg={this.props.errorObj.leadType || ""}
                        />
                    </Col>
                    <Col md="8">
                        <FormInput
                            name="queryText"
                            className="form-control-label"
                            id="queryText"
                            placeholder="inputPlaceHolder.lead.queryText"
                            type="textarea"
                            onChange={this.props.onChange}
                            label="inputLabel.lead.queryText"
                            value={this.props.valueObj.queryText || ""}
                            errorMsg={this.props.errorObj.queryText || ""}
                        />
                    </Col>
                </Row>
            </>
        );
    }
}

export default LeadForm;
