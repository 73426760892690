import PropTypes from "prop-types";
import React from "react";
import {FormGroup} from "reactstrap";
import {useTranslation} from "react-i18next";
import Select from "react-select";

const FormReactSelect = ({
                             selectKey,
                             name,
                             id,
                             onChange,
                             className,
                             value,
                             label,
                             errorMsg,
                             optionList,
                             optionLabel,
                             optionValue,
                             isDisabled,
                             isLoading,
                             isClearable,
                             isMulti,
                             isRtl,
                             isSearchable,
                             isOptionDisabled,
                             defaultValue,
                             placeHolder,
                             ...props
                         }) => {
    const {t} = useTranslation();

    const displayBlock = {display: "block"};

    const customStyles =
        errorMsg === ""
            ? {
                control: (provided, state) => ({
                    ...provided,
                    borderRadius: "0.25rem",
                }),
                valueContainer: (provided, state) => ({
                    ...provided,
                    padding: "8px 8px",
                }),
            }
            : {
                control: (provided, state) => ({
                    ...provided,
                    borderColor: "#fb6340",
                    borderRadius: "0.25rem",
                }),
                valueContainer: (provided, state) => ({
                    ...provided,
                    padding: "8px 8px",
                }),
            };
    return (
        <FormGroup>
            <label className={className} htmlFor={name}>
                {t(label)}
            </label>
            <div>
                <Select
                    key={selectKey}
                    styles={customStyles}
                    id={id}
                    placeholder={t(placeHolder)}
                    className={className}
                    classNamePrefix="select"
                    defaultValue={defaultValue}
                    inputValue={value}
                    isDisabled={isDisabled}
                    isLoading={isLoading}
                    isClearable={isClearable}
                    isMulti={isMulti}
                    isRtl={isRtl}
                    isSearchable={isSearchable}
                    name={name}
                    options={optionList}
                    onChange={onChange}
                    isOptionDisabled={isOptionDisabled}
                    getOptionValue={(option) => option[optionValue]}
                    getOptionLabel={(option) => option[optionLabel]}
                />
            </div>
            <div className="invalid-feedback" style={displayBlock}>
                {t(errorMsg)}
            </div>
        </FormGroup>
    );
};

FormReactSelect.defaultProps = {
    className: "form-control-label",
};

FormReactSelect.propTypes = {
    selectKey: PropTypes.string,
    id: PropTypes.string,
    errorMsg: PropTypes.string,
    value: PropTypes.any,
    label: PropTypes.string,
    optionValue: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    defaultValue: PropTypes.object,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    isClearable: PropTypes.bool,
    isMulti: PropTypes.bool.isRequired,
    isRtl: PropTypes.bool,
    isSearchable: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    optionList: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    isOptionDisabled: PropTypes.bool,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default FormReactSelect;
