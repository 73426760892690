/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {Button, Card, CardBody, CardHeader, Container, Form,} from "reactstrap";
import LeadService from "../../../services/leadService";
import LeadForm from "./Form";
import _ from "lodash";
import {leadTypes} from "../../../util/leadTypes"

class LeadCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorData: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
    console.log(this.state);
  }

  handleSelectChange(valueObj) {
    if (_.isNull(valueObj)) {
      this.setState({leadType: {}});
    } else {
      this.setState({leadType: valueObj.value});
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    LeadService.create(this.state).then((response) => {
      console.log(response.data);
      if (response.responseStatus === "SUCCESS") {
        this.props.history.push("/admin/lead/list");
      } else {
        this.setState({errorData: response.data});
        console.error("Error    ==>     " + response.message);
      }
    });
  }

  render() {
    return (
        <>
          <SimpleHeader
              name="Create"
              parentName="Lead"
              heading="ClashMate"
              // newBtnUrl="/admin/lead/create"
              // newBtnText="Create Lead"
          />
          <Container className="mt--6" fluid>
            <Card className="mb-4">
              <CardHeader>
                <h3 className="mb-0">Create Lead</h3>
              </CardHeader>
              <CardBody>
                <Form role="form" onSubmit={this.handleSubmit}>
                  <LeadForm
                      onChange={this.handleChange}
                      valueObj={this.state}
                      errorObj={this.state.errorData}
                      leadTypes={leadTypes}
                      onSelectChange={this.handleSelectChange}
                      selectedLead={this.state.leadType}
                  />
                  <div className="text-center">
                    <Button className="my-4" color="info" type="submit">
                      Submit
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Container>
        </>
    );
  }
}

export default LeadCreate;
