/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Row,
} from "reactstrap";
import MessageService from "../../../services/messageService";
import NotificationAlert from "react-notification-alert";
import _ from "lodash";
import Messages from "./Messages";

class CollapseableMessageList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      messageTypes: [],
      openedCollapses: [],
    };
    this.delete = this.delete.bind(this);
    this.notify = this.notify.bind(this);
  }

  componentDidMount() {
    MessageService.list().then((response) => {
      console.log(response);
      this.setState({ list: response.data });
      let messageTypes = _.groupBy(
        response.data,
        (message) => message.messageType
      );
      this.setState({
        messageTypes: Object.keys(messageTypes),
      });
    });
  }

  delete(uniqueId) {
    MessageService.delete(uniqueId).then((response) => {
      console.log(response);
      this.notify(
        response.responseStatus.toLowerCase(),
        response.responseStatus,
        response.message
      );
      //TODO: TO be fixed with redux implementation
      //   this.props.history.push("/admin/dashboard");
      MessageService.list().then((response) => {
        console.log(response);
        this.setState({ list: response.data });
      });
    });
  }

  notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  collapsesToggle = (collapse) => {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: [],
      });
    } else {
      this.setState({
        openedCollapses: [collapse],
      });
    }
  };

  render() {
    let obj = this;
    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <SimpleHeader
          name="List"
          parentName="Message"
          heading="ClashMate"
          // newBtnUrl="/admin/message/create"
          newBtnText="Create Message"
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col xl="12">
              <Card>
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Message List</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="accordion">
                    {this.state.messageTypes.map((messageType) => {
                      return (
                        <Card className="card-plain" key={messageType}>
                          <CardHeader
                            role="tab"
                            onClick={() => this.collapsesToggle(messageType)}
                            aria-expanded={this.state.openedCollapses.includes(
                              messageType
                            )}
                          >
                            <h5 className="mb-0">{messageType}</h5>
                          </CardHeader>
                          <Collapse
                            role="tabpanel"
                            isOpen={this.state.openedCollapses.includes(
                              messageType
                            )}
                          >
                            <CardBody>
                              <Messages
                                history={obj.props.history}
                                messageList={_.filter(
                                  this.state.list,
                                  (message) =>
                                    message.messageType === messageType
                                )}
                              />
                            </CardBody>
                          </Collapse>
                        </Card>
                      );
                    })}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default CollapseableMessageList;
