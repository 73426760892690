/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {Card, CardBody, CardHeader, Col, Container, FormGroup, Row} from "reactstrap";
import MessageService from "../../../services/messageService";

class MessageDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            messageData: {}
        };
    }

    componentDidMount() {
        const {uniqueId} = this.props.match.params;
        MessageService.fetch(uniqueId).then(response => {
            console.log(response.data);
            this.setState({messageData: response.data});
        });
    }

    render() {
        const {messageData} = this.state;
        return (
            <>
                <SimpleHeader
                    name="Details"
                    parentName="Message"
                    heading="ClashMate"
                    // newBtnUrl="/admin/message/create"
                    newBtnText="Create Message"
                />
                <Container className="mt--6" fluid>
                    <Card className="mb-4">
                        <CardHeader>
                            <h3 className="mb-0">{messageData.fullName}</h3>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols1Input"
                                        >
                                            Date Created
                                        </label>
                                        <p>{messageData.dateCreated}</p>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols2Input"
                                        >
                                            Message Key
                                        </label>
                                        <p>{messageData.key}</p>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols3Input"
                                        >
                                            Message Content
                                        </label>
                                        <p>{messageData.content}</p>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="4" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example4cols1Input"
                                        >
                                            Locale
                                        </label>
                                        <p>{messageData.locale}</p>
                                    </FormGroup>
                                </Col>
                                <Col md="4" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example4cols2Input"
                                        >
                                            Message Type
                                        </label>
                                        <p>{messageData.messageType}</p>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </>
        );
    }
}

export default MessageDetail;
