/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import _ from "lodash";
import React from "react";
import {Card, CardBody, CardHeader, Col, Container, FormGroup, Row,} from "reactstrap";
import NotificationQueueService from "../../../../services/notificationQueueService";

class NotificationQueueDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notificationQueueData: {},
            isToolbarHidden: false,
            isSubjectHidden: false,
        };
    }

    componentDidMount() {
        const {uniqueId} = this.props.match.params;
        NotificationQueueService.fetch(uniqueId).then((response) => {
            this.setState({notificationQueueData: response.data});
            console.log(response.data);
            this.setState({
                isToolbarHidden: _.includes(
                    ["SMS", "PUSH_WEB", "PUSH_MOBILE"],
                    response.data.notificationType
                ),
                isSubjectHidden: _.includes(
                    ["SMS", "PUSH_MOBILE"],
                    response.data.notificationType
                ),
            });
        });
    }

    render() {
        const {notificationQueueData} = this.state;
        return (
            <>
                <SimpleHeader
                    name="Details"
                    parentName="Notification"
                    heading="ClashMate"
                />
                <Container className="mt--6" fluid>
                    <Row>
                        <Col md="12">
                            <Card className="mb-4">
                                <CardHeader>
                                    <h3 className="mb-0">{notificationQueueData.notificationEvent}</h3>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md="12" className={this.state.isSubjectHidden ? "hidden" : ""}>
                                            <FormGroup>
                                                <label className="form-control-label">Subject</label>
                                                <p>{notificationQueueData.subject}</p>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-control-label">Notification Type</label>
                                                <p>{notificationQueueData.notificationType}</p>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-control-label">Credit Count</label>
                                                <p>{notificationQueueData.count}</p>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-control-label">Recipient</label>
                                                <p>{notificationQueueData.recipient}</p>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-control-label">Date Sent</label>
                                                <p>{notificationQueueData.dateCreated}</p>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="4">
                                            <FormGroup>
                                                <label className="form-control-label">Content</label>
                                                <p
                                                    dangerouslySetInnerHTML={{
                                                        __html: notificationQueueData.content,
                                                    }}
                                                ></p>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default NotificationQueueDetails;
