import React from "react";
import {Col, Row} from "reactstrap";
import _ from "lodash";
import FormInput from "../../../../components/common/form/FormInput";

class LedgerInvoiceForm extends React.Component {

    display = () => {
        return this.props.displayInvoice === true ? "" : "hidden"
    };

    displayInvoiceData = () => {
        return _.isEmpty(this.props.teacher) === true ? "hidden" : ""
    };

    render() {
        return (
            <>
                <div className={this.display()}>
                    <Row>
                        <Col md="4">
                            <FormInput
                                name="vendor"
                                className="form-control-label"
                                id="vendor"
                                placeholder="inputPlaceHolder.ledger.vendor"
                                type="text"
                                onChange={this.props.onChange}
                                label="inputLabel.ledger.vendor"
                                value={this.props.valueObj.vendor || ""}
                                errorMsg={this.props.errorObj.vendor || ""}
                            />
                        </Col>
                        <Col md="8">

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default LedgerInvoiceForm;
