import React from "react";
import { Col, Row } from "reactstrap";
import FormInput from "../../../components/common/form/FormInput";
import FormReactSelect from "../../../components/common/form/FormReactSelect";
import {nextStepStatus} from "../../../util/nextStepStatus";

class NextStepsForm extends React.Component {
  showField() {
    return this.props.isEdit === true ? "" : "hidden";
  }

  render() {
    return (
      <>
        <Row>
          <Col md="12">
            <FormInput
                name="todo"
                className="form-control-label"
                id="todo"
                placeholder="inputPlaceHolder.nextSteps.todo"
                type="textarea"
                onChange={this.props.onChange}
                label="inputLabel.nextSteps.todo"
                value={this.props?.valueObj?.todo || ""}
                errorMsg={this.props?.errorObj?.todo || ""}
            />
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <FormInput
                name="byWhen"
                className="form-control-label"
                id="byWhen"
                placeholder="inputPlaceHolder.nextSteps.byWhen"
                type="date"
                onChange={this.props.onChange}
                label="inputLabel.nextSteps.byWhen"
                value={this.props?.valueObj?.byWhen || ""}
                errorMsg={this.props?.errorObj?.byWhen || ""}
            />
          </Col>

          <Col md="4">
              <FormReactSelect
                  selectKey={this.props.selectKey}
                  isMulti={false}
                  className="form-control-label"
                  isSearchable={true}
                  label="inputLabel.nextSteps.nextStepsStatus"
                  name={"category"}
                  onChange={this.props.handleNextStepStatusChange}
                  optionValue={"value"}
                  defaultValue={this.props.selectedNextStepStatus}
                  optionList={this.props.nextStepStatus}
                  optionLabel={"key"}
                  placeHolder="inputPlaceHolder.nextSteps.nextStepsStatus"
                  errorMsg={this.props?.errorObj?.nextStepsStatus || ""}
              />
          </Col>
          <Col md="4">
              <FormReactSelect
                  selectKey={this.props.attendeesSelectkey}
                  isMulti={false}
                  className="form-control-label"
                  isSearchable={true}
                  label="inputLabel.nextSteps.assignedUser"
                  name={"assignedUser"}
                  onChange={this.props.handleAttendeesChange}
                  optionValue={"uniqueId"}
                  defaultValue={this.props.selectedAttendees}
                  optionList={this.props.attendesForSelection}
                  optionLabel={"fullName"}
                  placeHolder="inputPlaceHolder.nextSteps.assignedUser"
                  errorMsg={this.props?.errorObj?.assignedUser || ""}
              />
          </Col>
        </Row>
        <Row>
          <Col>

          </Col>
        </Row>
      </>
    );
  }
}

export default NextStepsForm;
