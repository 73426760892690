/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
} from "reactstrap";
import LeadService from "../../../services/leadService";
import LeadRemarkService from "../../../services/leadRemarkService";
import LeadRemarkForm from "./LeadRemarkForm";
import Moment from "react-moment";

class LeadDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leadData: {
        closedByAdmin: { fullName: "N/A" },
        createdByAdmin: { fullName: "N/A" },
      },
      remarkList: [],
      remark: "",
      errorData: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ remark: event.target.value });
  }

  //Todo:Remark Creation By another admin is not working properly.
  handleSubmit(event) {
    event.preventDefault();
    const leadUid = this.props.match.params.uniqueId;
    let formdata = { remark: this.state.remark, leadUid: leadUid };
    LeadRemarkService.create(formdata).then((response) => {
      if (response.responseStatus === "SUCCESS") {
        LeadRemarkService.fetchRemarksByLead(leadUid).then((responseList) => {
          this.setState({ remarkList: responseList.data });
          this.setState({ remark: "" });
        });
      } else {
        this.setState({ errorData: response.data });
        console.error("Error    ==>     " + response.message);
      }
    });
  }

  async componentDidMount() {
    const { uniqueId } = this.props.match.params;
    await LeadService.fetch(uniqueId).then((response) => {
      let leadData = response.data;
      if (leadData.closedByAdmin === null) {
        leadData.closedByAdmin = {};
        this.setState({ leadData: leadData });
      } else {
        this.setState({ leadData: leadData });
      }
    });

    await LeadRemarkService.fetchRemarksByLead(uniqueId).then((response) => {
      this.setState({ remarkList: response.data });
    });
  }

  render() {
    const { leadData } = this.state;
    return (
      <>
        <SimpleHeader
          name="Details"
          parentName="Lead"
          heading="ClashMate"
          // newBtnUrl="/admin/section/create"
          // newBtnText="Create Section"
        />
        <Container className="mt--6" fluid>
          <Card className="mb-4">
            <CardHeader>
              <h3 className="mb-0">{leadData.queryText}</h3>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      First Name
                    </label>
                    <p>{leadData.firstName}</p>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Middle Name
                    </label>
                    <p>{leadData.middleName || "-"}</p>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Last Name
                    </label>
                    <p>{leadData.lastName}</p>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols4Input"
                    >
                      Phone Number
                    </label>
                    <p>{leadData.phoneNumber}</p>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols5Input"
                    >
                      Email Address
                    </label>
                    <p>{leadData.emailAddress}</p>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols6Input"
                    >
                      Guardian Name
                    </label>
                    <p>{leadData.guardianName}</p>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols7Input"
                    >
                      Lead Type
                    </label>
                    <p>{leadData.leadType}</p>
                  </FormGroup>
                </Col>
                {/*Todo:NotDispalyingData*/}
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols8Input"
                    >
                      Created By
                    </label>
                    <p>
                      {this.state.leadData.createdByAdmin.fullName || "N/A"}
                    </p>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols10Input"
                    >
                      Date Created
                    </label>
                    <p>{leadData.dateCreated}</p>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols11Input"
                    >
                      Closed On
                    </label>
                    <p>{leadData.leadClosedOn || "N/A"}</p>
                  </FormGroup>
                </Col>
                {/*Todo:NotDisplayingData*/}
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols9Input"
                    >
                      Closed By
                    </label>
                    <p>{leadData.closedByAdmin.fullName || "N/A"}</p>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className="mb-4">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <div className="col">
                  <h3 className="mb-0">Remark</h3>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              <Form role="form" onSubmit={this.handleSubmit}>
                <LeadRemarkForm
                  onChange={this.handleChange}
                  valueObj={this.state}
                  errorObj={this.state.errorData}
                />
                <div className="text-center">
                  <Button className="my-4" color="info" type="submit">
                    Add Remark
                  </Button>
                </div>
              </Form>
            </CardBody>
            <CardBody>
              {this.state.remarkList.map(function (remark, index) {
                return (
                  <Row key={index}>
                    <Col md="12">
                      <Row>
                        <Col md="4">
                          <span className="h6">
                            <strong>By</strong> {remark.remarkAddedBy.fullName}
                          </span>
                        </Col>
                        <Col md="4"></Col>
                        <Col md="4">
                          <span className="h6">
                            <strong>Added </strong>{" "}
                            <Moment fromNow>{remark.dateCreated}</Moment>
                          </span>
                        </Col>
                      </Row>
                      <p className="">{remark.remark}</p>
                    </Col>
                  </Row>
                );
              })}
            </CardBody>
          </Card>
        </Container>
      </>
    );
  }
}

export default LeadDetails;
