/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup, Input,
    InputGroup,
    InputGroupAddon, InputGroupText, Modal,
    Row,
} from "reactstrap";
import MeetingReportService from "../../../services/meetingReportService";
import SummaryForm from "../summary/Form";
import _ from "lodash";
import ContactService from "../../../services/contactService";
import UtilService from "../../../services/utilService";
import AdminService from "../../../services/adminService";
import ContentService from "../../../services/xgContentService";
import SummaryService from "../../../services/summaryService";
import NotificationAlert from "react-notification-alert";
import SummaryDetails from "../summary/Details";

class MeetingDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contentData: {},
            errorObj : {},
            attendeesSelectKey :UtilService.uuidv4(),
            attendesForSelection : []
        };

        this.handleSummarySubmit = this.handleSummarySubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleAttendeesChange = this.handleAttendeesChange.bind(this);
    }
    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
        console.log(this.state);
    }

    componentDidMount() {
        const {uniqueId} = this.props.match.params;
        this.setState({meetingReportUID: uniqueId})
        MeetingReportService.fetch(uniqueId).then((response) => {
            console.log(response.data);
            this.setState({contentData: response.data});
        });
        AdminService.list().then((response) => {
            console.log(response);
            this.setState({attendesForSelection: response.data, attendeesSelectKey :UtilService.uuidv4() });
        });
        console.log(this.state.attendesForSelection)
    }
    handleAttendeesChange = (valueObj,event) => {
        if (_.isNull(valueObj)) {
            this.setState({selectedAttendees: []});
        } else {
            this.setState({selectedAttendees: valueObj});
            const attendees = _.map(valueObj, "uniqueId")
            this.setState({sharedWith: attendees});
        }
    }

    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };

    notify = (type, title, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5,
        };
        this.refs.notificationAlert.notificationAlert(options);
    }

    handleSummarySubmit (event) {
        event.preventDefault();
        SummaryService.create(this.state).then(response => {
            this.notify(
                response.responseStatus.toLowerCase(),
                response.responseStatus,
                response.message
            );
            if (response.responseStatus === "SUCCESS") {
                this.toggleModal("formModal")
            } else {
                this.setState({errorData: response.data});
                console.error("Error    ==>     " + response.message);
            }
        });
    }

    render() {
        const {contentData} = this.state;
        return (
            <>
                <div className="rna-wrapper">
                    <NotificationAlert ref="notificationAlert"/>
                </div>
                <SimpleHeader
                    name="Details"
                    parentName="Meeting"
                    heading="XareGrowth"
                    newBtnUrl="/admin/meetingReport/list"
                    newBtnText="List All Meetings"
                />
                <Container className="mt--6" fluid>
                    <Card className="mb-4">
                        <CardHeader>
                            <h3 className="mb-0">{contentData?.company?.legalName}</h3>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols1Input"
                                        >
                                            Date
                                        </label>
                                        <p>{contentData.date}</p>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols2Input"
                                        >
                                            Event Type
                                        </label>
                                        <p>
                                            {contentData.eventType}
                                        </p>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols3Input"
                                        >
                                            Company
                                        </label>
                                        <p>{contentData?.company?.legalName}</p>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="4" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example4cols1Input"
                                        >
                                            Attendees
                                        </label>
                                        <p>{contentData?.attendees?.map(function (value, index) {
                                            return <h4 key={index}
                                                       className="badge badge-lg badge-primary">{value.fullName}</h4>
                                        })}</p>
                                    </FormGroup>
                                </Col>
                                <Col md="4" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example4cols2Input"
                                        >
                                            Date Created
                                        </label>
                                        <p>{contentData.dateCreated}</p>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Row>
                        <Col md="12">
                            <Button
                                block
                                color="default"
                                type="button"
                                onClick={() => this.toggleModal("formModal")}
                            >
                                Add Summary
                            </Button>
                            <Modal
                                className="modal-dialog-centered"
                                size="lg"
                                isOpen={this.state.formModal}
                                toggle={() => this.toggleModal("formModal")}
                            >
                                <div className="modal-body p-0">
                                    <Card className="bg-secondary shadow border-0">
                                        <CardHeader className="bg-transparent pb-5">
                                            <div className="text-muted text-center mt-2 mb-3">
                                                <small>Add Summary</small>
                                            </div>
                                        </CardHeader>
                                        <CardBody className="px-lg-5 py-lg-5">
                                            <Form role="form" onSubmit={this.handleSummarySubmit}>


                                                <SummaryForm onChange={this.handleChange}
                                                             valueObj={this.state}
                                                             errorObj={this.state.errorData}
                                                             attendeesSelectkey={this.state.attendeesSelectKey}
                                                             handleAttendeesChange={this.handleAttendeesChange}
                                                             selectedAttendees={this.state.selectedAttendees}
                                                             attendesForSelection={this.state.attendesForSelection}
                                                />
                                                <div className="text-center">
                                                    <Button className="my-4" color="info" type="submit">
                                                        Submit
                                                    </Button>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </div>
                            </Modal>
                        </Col>
                    </Row>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <Row>
                        <Col md="12">
                        </Col>
                    </Row>

                    <Row>
                        <Col md="12">
                            {contentData?.summaries?.map((summary, index) => (
                                <div key={index}>
                                    <SummaryDetails summary={summary} index={index + 1}/>
                                </div>
                            ))}
                        </Col>
                    </Row>
                </Container>
                <br/>
                <br/>
                <br/>
                <br/>
            </>
        );
    }
}

export default MeetingDetails;
