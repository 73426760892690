/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
    Card,
    CardHeader,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledDropdown,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import LeadService from "../../../services/leadService";
import LeadStats from "./LeadStats";
import _ from 'lodash';
import {leadTypes} from "../../../util/leadTypes"
import FormReactSelect from "../../../components/common/form/FormReactSelect";

class LeadList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayList: [],
            alert: null,
            list: [],
            selectedFilter: {},
            hotLeads: [],
            coldLeads: [],
            neutralLeads: [],
            closedLeads: []
        };
        this.delete = this.delete.bind(this);
        this.notify = this.notify.bind(this);
    }

    componentDidMount() {
        LeadService.list().then((response) => {
            this.setState({list: response});
            this.setState({displayList: response});
            let hotLeads = _.filter(this.state.list, (lead) => lead.leadType === "HOT");
            let coldLeads = _.filter(this.state.list, (lead) => lead.leadType === "COLD");
            let neutralLeads = _.filter(this.state.list, (lead) => lead.leadType === "NEUTRAL");
            let closedLeads = _.filter(this.state.list, (lead) => lead.closedByAdmin !== null);
            this.setState({hotLeads: hotLeads});
            this.setState({coldLeads: coldLeads});
            this.setState({neutralLeads: neutralLeads});
            this.setState({closedLeads: closedLeads});
        });
    }

    delete(uniqueId) {
        this.setState({alert: null});
        LeadService.delete(uniqueId).then((response) => {
            console.log(response);
            this.notify(
                response.responseStatus.toLowerCase(),
                response.responseStatus,
                response.message
            );
            //TODO: TO be fixed with redux implementation
            //   this.props.history.push("/admin/dashboard");
            LeadService.list().then((response) => {
                this.setState({list: response});
                if (this.state.selectedFilter in leadTypes) {
                    let leads = _.filter(this.state.list, (lead) => lead.leadType === this.state.selectedFilter)
                    this.setState({displayList: leads});
                } else {
                    this.setState({displayList: response});
                }
                let hotLeads = _.filter(this.state.list, (lead) => lead.leadType === "HOT");
                let coldLeads = _.filter(this.state.list, (lead) => lead.leadType === "COLD");
                let neutralLeads = _.filter(this.state.list, (lead) => lead.leadType === "NEUTRAL");
                let closedLeads = _.filter(this.state.list, (lead) => lead.closedByAdmin !== null);
                this.setState({hotLeads: hotLeads});
                this.setState({coldLeads: coldLeads});
                this.setState({neutralLeads: neutralLeads});
                this.setState({closedLeads: closedLeads});
            });
        });
    }

    close(uniqueId) {
        LeadService.close(uniqueId).then((response) => {
            console.log(response);
            this.notify(
                response.responseStatus.toLowerCase(),
                response.responseStatus,
                response.message
            );
            //TODO: TO be fixed with redux implementation
            //   this.props.history.push("/admin/dashboard");
            LeadService.list().then((response) => {
                console.log(response);
                this.setState({list: response});
                let closedLeads = _.filter(this.state.list, (lead) => lead.leadType === "CLOSED");
                this.setState({closedLeads: closedLeads});
            });
        });
    }

    notify = (type, title, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5,
        };
        this.refs.notificationAlert.notificationAlert(options);
    };

    onSelectChange = (valueObj) => {
        let filter = valueObj.value;
        this.setState({selectedFilter: filter});
        let leads = _.filter(this.state.list, (lead) => lead.leadType === filter);
        this.setState({displayList: leads});
    }

    hideDeleteAlert = () => {
        this.setState({
            alert: null
        });
    };

    confirmDeleteAlert = (uniqueId) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{display: "block"}}
                    title="Are you sure?"
                    onConfirm={() => this.delete(uniqueId)}
                    onCancel={this.hideDeleteAlert}
                    showCancel
                    confirmBtnBsStyle="danger"
                    confirmBtnText="Yes, delete it!"
                    cancelBtnBsStyle="secondary"
                    cancelBtnText="Cancel"
                    btnSize=""
                >
                    You won't be able to revert this!
                    <br/>
                    All the lead remarks will be deleted as well!
                </ReactBSAlert>
            )
        });
    };

    render() {
        let obj = this;
        return (
            <>

                <div className="rna-wrapper">
                    <NotificationAlert ref="notificationAlert"/>
                </div>
                <SimpleHeader
                    name="List"
                    parentName="Lead"
                    heading="ClashMate"
                    newBtnUrl="/admin/lead/create"
                    newBtnText="Create Lead"
                />
                <LeadStats hotLeads={this.state.hotLeads.length} coldLeads={this.state.coldLeads.length}
                           neutralLeads={this.state.neutralLeads.length}
                           closedLeads={this.state.closedLeads.length}/>
                {this.state.alert}
                <Container className="mt--6" fluid>
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <Col md="6">
                                            <div className="h3">
                                                <span className="mb-0">Lead List</span>
                                            </div>
                                        </Col>
                                        <Col md="5">
                                            <FormReactSelect
                                                isMulti={false}
                                                className="form-control-label"
                                                isSearchable={true}
                                                // label="inputLabel.lead.leadType"
                                                name={"leadType"}
                                                onChange={this.onSelectChange}
                                                optionValue={"value"}
                                                optionList={leadTypes}
                                                optionLabel={"key"}
                                                placeHolder="inputPlaceHolder.lead.leadType"
                                                errorMsg={""}
                                            />
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                    <tr>
                                        <th className="sort" data-sort="name" scope="col">
                                            #
                                        </th>
                                        <th className="sort" data-sort="budget" scope="col">
                                            Date Created
                                        </th>
                                        <th className="sort" data-sort="status" scope="col">
                                            Student Name
                                        </th>
                                        <th className="sort" data-sort="status" scope="col">
                                            Guardian Name
                                        </th>
                                        <th className="sort" data-sort="status" scope="col">
                                            Phone Number
                                        </th>
                                        <th className="sort" data-sort="status" scope="col">
                                            Email Address
                                        </th>
                                        <th className="sort" data-sort="status" scope="col">
                                            Lead Type
                                        </th>
                                        <th scope="col"/>
                                    </tr>
                                    </thead>
                                    <tbody className="list">
                                    {this.state.displayList.map(function (value, index) {
                                        return (
                                            <tr key={index}>
                                                <td className="budget">{index + 1}</td>
                                                <td className="budget">{value.dateCreated}</td>
                                                <td className="budget">{value.fullName}</td>
                                                <td className="budget">{value.guardianName}</td>
                                                <td className="budget">{value.phoneNumber}</td>
                                                <td className="budget">{value.emailAddress}</td>
                                                <td className="budget">{value.leadType}</td>
                                                <td className="text-right">
                                                    <UncontrolledDropdown>
                                                        <DropdownToggle
                                                            color=""
                                                            size="sm"
                                                            className="btn-icon-only text-light"
                                                        >
                                                            <i className="fas fa-ellipsis-v"/>
                                                        </DropdownToggle>
                                                        <DropdownMenu
                                                            className="dropdown-menu-arrow"
                                                            right
                                                        >
                                                            <DropdownItem
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    obj.props.history.push(
                                                                        `/admin/lead/show/${value.uniqueId}`
                                                                    );
                                                                }}
                                                            >
                                                                Details
                                                            </DropdownItem>
                                                            <DropdownItem
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    obj.props.history.push(
                                                                        `/admin/lead/edit/${value.uniqueId}`
                                                                    );
                                                                }}
                                                            >
                                                                Edit
                                                            </DropdownItem>
                                                            <DropdownItem
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    obj.close(value.uniqueId);
                                                                }}
                                                            >
                                                                Close Lead
                                                            </DropdownItem>
                                                            <DropdownItem
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    obj.confirmDeleteAlert(`${value.uniqueId}`);
                                                                }}
                                                            >
                                                                Delete
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </Table>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default LeadList;
