/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import {ContentState, convertFromHTML, convertToRaw, EditorState, Modifier,} from "draft-js";
import draftToHtml from "draftjs-to-html";
import _ from "lodash";
import React from "react";
import {Button, Card, CardBody, CardHeader, Container, Form,} from "reactstrap";
import NotificationService from "../../../services/notificationService";
import NotificationForm from "./Form";

class NotificationEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorData: {},
            editorState: undefined,
            isToolbarHidden: false,
            isSubjectHidden: false,
            characterCount: 0,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleContentChange = this.handleContentChange.bind(this);
        this.sendTextToEditor = this.sendTextToEditor.bind(this);
        this.insertText = this.insertText.bind(this);
        this.focusEditor = this.focusEditor.bind(this);
    }

    componentDidMount() {
        const {uniqueId} = this.props.match.params;
        NotificationService.fetch(uniqueId).then((response) => {
            this.setState(response.data);
            this.setState({
                isToolbarHidden: _.includes(
                    ["SMS", "PUSH_WEB", "PUSH_MOBILE"],
                    response.data.notificationType
                ),
                isSubjectHidden: _.includes(
                    ["SMS", "PUSH_MOBILE"],
                    response.data.notificationType
                ),
                isCountedMedium: _.includes(["SMS"], response.data.notificationType),
            });
            if (this.state.isCountedMedium) {
                this.setState({
                    characterCount: response.data.content.length,
                });
            }
            let contentTextHMTL = response.data.content || "";
            const blocksFromHTML = convertFromHTML(contentTextHMTL);
            const content = ContentState.createFromBlockArray(blocksFromHTML);
            this.setState({
                editorState: EditorState.createWithContent(content),
            });
        });
    }

    handleContentChange(editorState) {
        this.setState({editorState});
        if (this.state.isCountedMedium) {
            this.setState({
                characterCount: editorState.getCurrentContent().getPlainText().length,
            });
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]:
                event.target.type === "checkbox"
                    ? event.target.checked
                    : event.target.value,
        });
        console.log(this.state);
    }

    focusEditor = () => {
        if (this.editor) {
            this.editor.focusEditor();
            console.log("Editor has the focus now");
        }
    };

    sendTextToEditor = (text) => {
        const editorState = this.insertText(text, this.state.editorState);
        this.setState({
            editorState: editorState,
        });
        this.handleContentChange(editorState);
        this.focusEditor();
    };

    insertText = (text, editorState) => {
        const currentContent = editorState.getCurrentContent(),
            currentSelection = editorState.getSelection();

        const newContent = Modifier.replaceText(
            currentContent,
            currentSelection,
            text
        );

        const newEditorState = EditorState.push(
            editorState,
            newContent,
            "insert-characters"
        );
        return EditorState.forceSelection(
            newEditorState,
            newContent.getSelectionAfter()
        );
    };

    handleSubmit(event) {
        event.preventDefault();
        const {editorState} = this.state;
        let formData = {
            content: this.state.isToolbarHidden
                ? editorState.getCurrentContent().getPlainText()
                : draftToHtml(convertToRaw(editorState.getCurrentContent())),
            subject: this.state.subject,
            uniqueId: this.state.uniqueId,
            isEnabled: this.state.isEnabled,
        };
        NotificationService.update(formData).then((response) => {
            if (response.responseStatus === "SUCCESS") {
                this.props.history.push("/admin/notification/list/EMAIL");
            } else {
                this.setState({errorData: response.data});
                console.error("Error    ==>     " + response.message);
            }
        });
    }

    render() {
        return (
            <>
                <SimpleHeader name="Edit" parentName="Notification" heading="ClashMate"/>
                <Container className="mt--6" fluid>
                    <Card className="mb-4">
                        <CardHeader>
                            <h3 className="mb-0">
                                {this.state.notificationType}&nbsp;|&nbsp;
                                {this.state.notificationEvent}
                            </h3>
                        </CardHeader>
                        <CardBody>
                            <Form role="form" onSubmit={this.handleSubmit}>
                                <NotificationForm
                                    onChange={this.handleChange}
                                    valueObj={this.state}
                                    errorObj={this.state.errorData}
                                    sendTextToEditor={this.sendTextToEditor}
                                    isEdit={true}
                                    obj={this}
                                />
                                <div className="text-center">
                                    <Button className="my-4" color="info" type="submit">
                                        Update
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
            </>
        );
    }
}

export default NotificationEdit;
