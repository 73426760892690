/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import _ from "lodash";
import React from "react";
import {
	Card,
	CardHeader,
	Col,
	Container,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Row,
	Table,
	UncontrolledDropdown,
} from "reactstrap";
import FormInputSelect from "../../../../components/common/form/FormInputSelect";
import NotificationQueueService from "../../../../services/notificationQueueService";
import { NotificationType } from "../../../../util/notificationConstant";
import NotificationQueueCardsHeaderRow from "./NotificationQueueCardsHeaderRow";

class NotificationQueueList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			notificationQueueList: [],
			notificationType: "EMAIL",
			totalCount: 0,
			sentCount: 0,
			errorCount: 0,
		};
		this.notificationTypeChange = this.notificationTypeChange.bind(this);
		this.fetchNotificationQueueList = this.fetchNotificationQueueList.bind(this);
	}

	componentDidMount() {
		this.fetchNotificationQueueList("EMAIL");
	}

	fetchNotificationQueueList(notificationType) {
		NotificationQueueService.list(notificationType).then((response) => {
			const notificationQueueList = response.data;
			let sentCount = 0,
				errorCount = 0;
			_.forEach(notificationQueueList, (notificationQueue) => {
				notificationQueue.notificationStatus === "SUCCESS"
					? (sentCount += 1)
					: (errorCount += 1);
			});
			this.setState({
				notificationQueueList: notificationQueueList,
				totalCount: notificationQueueList.length,
				sentCount: sentCount,
				errorCount: errorCount,
			});
		});
	}

	notificationTypeChange(event) {
		const notificationType = event.target.value;
		this.setState({ notificationType: notificationType });
		this.fetchNotificationQueueList(notificationType);
	}

	render() {
		let obj = this;
		return (
			<>
				<SimpleHeader
					name="List"
					parentName="Notification Queue"
					heading="ClashMate"
					cardsHeaderRow={
						<NotificationQueueCardsHeaderRow
							totalCount={this.state.totalCount}
							sentCount={this.state.sentCount}
							errorCount={this.state.errorCount}
						/>
					}
				/>
				<Container className="mt--6" fluid>
					<Row>
						<Col xl="12">
							<Card>
								<CardHeader className="border-0">
									<Row>
										<Col md="8">
											<h3 className="mb-0">Notification Queue List</h3>
										</Col>
										<Col md="4">
											<FormInputSelect
												name="notificationType"
												className="form-control-label"
												id="notificationType"
												onChange={this.notificationTypeChange}
												optionList={NotificationType}
												optionKey="key"
												optionValue="value"
												inputClassName="form-control-sm"
												isLabelShown={false}
												value={this.state.notificationType}
											/>
										</Col>
									</Row>
								</CardHeader>
								<Table className="align-items-center table-flush" responsive>
									<thead className="thead-light">
										<tr>
											<th className="sort" data-sort="name" scope="col">
												#
											</th>
											<th className="sort" data-sort="status" scope="col">
												Dated
											</th>
											<th className="sort" data-sort="status" scope="col">
												Event
											</th>
											<th className="sort" data-sort="status" scope="col">
												Recipient
											</th>
											<th className="sort" data-sort="status" scope="col">
												Status
											</th>
											<th scope="col" />
										</tr>
									</thead>
									<tbody className="list">
										{this.state.notificationQueueList.map(function (value, index) {
											return (
												<tr key={index}>
													<td className="budget">{index + 1}</td>
													<td className="budget">{value.dateCreated}</td>
													<td className="budget">{value.notificationEvent}</td>
													<td className="budget">{value.recipient}</td>
													<td className="budget">{value.notificationStatus}</td>
													<td className="text-right">
														<UncontrolledDropdown>
															<DropdownToggle
																color=""
																size="sm"
																className="btn-icon-only text-light"
															>
																<i className="fas fa-ellipsis-v" />
															</DropdownToggle>
															<DropdownMenu className="dropdown-menu-arrow" right>
																<DropdownItem
																	onClick={(e) => {
																		e.preventDefault();
																		obj.props.history.push(
																			`/admin/notificationQueue/show/${value.uniqueId}`
																		);
																	}}
																>
																	Details
																</DropdownItem>
															</DropdownMenu>
														</UncontrolledDropdown>
													</td>
												</tr>
											);
										})}
									</tbody>
								</Table>
							</Card>
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}

export default NotificationQueueList;
