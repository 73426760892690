/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {Button, Card, CardBody, CardHeader, Container, Form} from "reactstrap";
import ContentService from "../../../services/xgContentService";
import ContentForm from "./Form";
import {contentCategory} from "../../../util/contentCategory";
import {contentType} from "../../../util/contentType";
import {contentArticleType} from "../../../util/contentArticleType";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import _ from "lodash";

class ContentCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorData: {},
            editorState: EditorState.createEmpty(),
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleArticleTypeChange = this.handleArticleTypeChange.bind(this);
        this.handleContentTypeChange = this.handleContentTypeChange.bind(this);
        this.handleContentChange = this.handleContentChange.bind(this);
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
        console.log(this.state);
    }

    handleContentChange(editorState) {
        this.setState({ editorState });
    }


    handleSubmit(event) {
        event.preventDefault();
        if(this.state.selectedContentType.key === "TEXT"){
            console.log(convertToRaw(this.state.editorState.getCurrentContent()))
            this.setState({content: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))})
        }

        ContentService.create(this.state).then(response => {
            console.log(response.data);
            if (response.responseStatus === "SUCCESS") {
                this.props.history.push("/admin/content/list");
            } else {
                this.setState({errorData: response.data});
                console.error("Error    ==>     " + response.message);
            }
        });
    }

    uploadImageCallback (file)  {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                resolve({ data: { link: reader.result } });
            };
            reader.onerror = reject;
            reader.readAsDataURL(file); // Converts the file to a base64 URL
        });
    };

    handleSelectChange(valueObj) {
        if (_.isNull(valueObj)) {
            this.setState({selectedCategory: {}, category: ""});
        } else {
            this.setState({selectedCategory: valueObj, category: valueObj.key});
        }
    }

    handleArticleTypeChange = (valueObj, event) => {
        if (_.isNull(valueObj)) {
            this.setState({selectedArticleType: []});
        } else {
            this.setState({selectedArticleType: valueObj});
            const keys = _.map(valueObj, "key")
            this.setState({articleTypes: keys});
        }
    };

    handleContentTypeChange = (valueObj, event) => {
        if (_.isNull(valueObj)) {
            this.setState({selectedContentType:  [], contentType :""});
        } else {
            this.setState({selectedContentType: valueObj, contentType : valueObj.key});
        }
    };



    render() {
        return (
            <>
                <SimpleHeader
                    name="Create"
                    parentName="Content"
                    heading="XareGrowth"
                    newBtnUrl="/admin/content/list"
                    newBtnText="List Contents"
                />
                <Container className="mt--6" fluid>
                    <Card className="mb-4">
                        <CardHeader>
                            <h3 className="mb-0">Create Content</h3>
                        </CardHeader>
                        <CardBody>
                            <Form role="form" onSubmit={this.handleSubmit}>
                                <ContentForm
                                    onChange={this.handleChange}
                                    valueObj={this.state}
                                    errorObj={this.state.errorData}
                                    contentCategories={contentCategory}
                                    onSelectChange={this.handleSelectChange}
                                    selectedCategory={this.state.selectedCategory}
                                    onArticleTypeChange={this.handleArticleTypeChange}
                                    selectedArticleType={this.state.selectedArticleType}
                                    articleTypes={contentArticleType}
                                    onContentTypeChange={this.handleContentTypeChange}
                                    selectedContentType={this.state.selectedContentType}
                                    contentTypes = {contentType}
                                    obj={this}
                                    uploadImageCallback = {this.uploadImageCallback}
                                />
                                <div className="text-center">
                                    <Button className="my-4" color="info" type="submit">
                                        Submit
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
            </>
        );
    }
}

export default ContentCreate;
