import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {Button, Card, CardBody, CardHeader, Container, Form,} from "reactstrap";
import _ from "lodash";
import {ledgerType} from "../../../../util/ledgerType";
import {ledgerDurationType} from "../../../../util/ledgerDurationType";
import {paymentTerm} from "../../../../util/paymentTerm";
import {paymentStatus} from "../../../../util/paymentStatus";

import LedgerForm from "./LedgerForm";
import LedgerFeeForm from "./LedgerFeeForm";
import LedgerSalaryForm from "./LedgerSalaryForm";
import StudentService from "../../../../services/studentService";
import CourseSectionSubjectStudentService from "../../../../services/courseSectionSubjectStudentService";
import CourseSectionSubjectTeacherService from "../../../../services/courseSectionSubjectTeacherService";
import TeacherService from "../../../../services/teacherService";
import LedgerInvoiceForm from "./LedgerInvoiceForm";
import LedgerService from "../../../../services/ledgerService";

class LedgerCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            description: "",
            displayFee: true,
            displaySalary: false,
            displayInvoice: false,
            displayNumberOfRecords: false,
            errorData: {},
            type: "FEES",
            paymentStatus: "UN_PAID",
            ledgerDurationType: "MONTHLY",
            paymentTerm: "ONCE",
            selectedStudents: [],
            selectedTeachers: [],
            studentList: [],
            teacherList: [],
            student: {},
            teacher: {},
            dueDate: null,
            amount: 0,
            numberOfRecords: 1,
            vendor: "",
        }
        ;
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    handlePaymentTermSelectChange = (valueObj, event) => {
        if (_.isNull(valueObj)) {
            this.setState({paymentTerm: {}});
        } else {
            if (valueObj.value === "ONCE") {
                this.setState({displayNumberOfRecords: false});
                this.setState({numberOfRecords: 1});

            } else {
                this.setState({displayNumberOfRecords: true});
            }
            this.setState({paymentTerm: valueObj.value});
        }
    };

    handleLedgerDurationTypeSelectChange = (valueObj, event) => {
        if (_.isNull(valueObj)) {
            this.setState({ledgerDurationType: {}});
        } else {
            this.setState({ledgerDurationType: valueObj.value});
        }
    };

    handlePaymentStatusSelectChange = (valueObj, event) => {
        if (_.isNull(valueObj)) {
            this.setState({paymentStatus: {}});
        } else {
            this.setState({paymentStatus: valueObj.value});
        }
    };

    handleTypeSelectChange = (valueObj, event) => {
        if (_.isNull(valueObj)) {
            this.setState({type: {}});
        } else {
            if (valueObj.value === "FEES") {
                this.setState({
                    displayFee: true,
                    displaySalary: false,
                    displayInvoice: false,
                    student: {},
                    teacher: {},
                    errorData: {},
                });
            } else if (valueObj.value === "SALARY") {
                this.setState({
                    displayFee: false,
                    displaySalary: true,
                    displayInvoice: false,
                    student: {},
                    teacher: {},
                    errorData: {},
                });
            } else if (valueObj.value === "INVOICE") {
                this.setState({
                    displayFee: false,
                    displaySalary: false,
                    displayInvoice: true,
                    student: {},
                    teacher: {},
                    errorData: {},
                });
            }
            this.setState({type: valueObj.value});
        }
    };

    handleStudentSelectChange = (valueObj, event) => {
        if (_.isNull(valueObj)) {
            this.setState({selectedStudents: []});
        } else {
            this.displayStudentDetails(valueObj[valueObj.length - 1].uniqueId);
            this.setState({selectedStudents: valueObj});
            this.setState({student: valueObj[valueObj.length - 1]});
        }
    };

    handleTeacherSelectChange = (valueObj, event) => {
        if (_.isNull(valueObj)) {
            this.setState({selectedTeachers: []});
        } else {
            this.displayTeacherDetails(valueObj[valueObj.length - 1].uniqueId);
            this.setState({selectedTeachers: valueObj});
            this.setState({teacher: valueObj[valueObj.length - 1]});
        }
    };

    displayStudentDetails = async (uniqueId) => {
        await CourseSectionSubjectStudentService.fetchCourseSectionSubjects(uniqueId).then((response) => {
            console.log(response.data)
            let allSubjects = _.uniqBy(_.map(response.data, "subject"), "uniqueId");
            let allCourses = _.uniqBy(_.map(response.data, "course"), "uniqueId");
            let allSections = _.uniqBy(_.map(response.data, "section"), "uniqueId");
            this.setState({allSubjects: allSubjects, allCourses: allCourses, allSections: allSections});
        });
    };

    displayTeacherDetails = async (uniqueId) => {
        await CourseSectionSubjectTeacherService.fetchCourseSectionSubjects(uniqueId).then((response) => {
            let allSubjects = _.uniqBy(_.map(response.data, "subject"), "uniqueId");
            let allCourses = _.uniqBy(_.map(response.data, "course"), "uniqueId");
            let allSections = _.uniqBy(_.map(response.data, "section"), "uniqueId");
            this.setState({allSubjects: allSubjects, allCourses: allCourses, allSections: allSections});
        });
    };

    populateStudents = async () => {
        const studentList = await StudentService.list().then((res) => {
            return res;
        });
        this.setState({studentList: studentList});
    }

    populateTeachers = async () => {
        const teacherList = await TeacherService.list().then((res) => {
            return res;
        });
        this.setState({teacherList: teacherList});
    }

    componentDidMount() {
        this.populateStudents();
        this.populateTeachers();
    }

    populateFormData = () => {
        let formData = {
            type: this.state.type,
            paymentStatus: this.state.paymentStatus,
            ledgerDurationType: this.state.ledgerDurationType,
            paymentTerm: this.state.paymentTerm,
            dueDate: this.state.dueDate,
            amount: this.state.amount,
            description: this.state.description,
            numberOfRecords: this.state.numberOfRecords
        };
        if (this.state.type === "FEES") {
            let studentUid = _.map(
                this.state.selectedStudents,
                (student) => student.uniqueId
            )
            formData = {
                ...formData, studentUid: studentUid
            }
        } else if (this.state.type === "SALARY") {
            let teacherUid = _.map(
                this.state.selectedTeachers,
                (student) => student.uniqueId
            )
            formData = {...formData, teacherUid: teacherUid}
        } else if (this.state.type === "INVOICE") {
            formData = {...formData, dueDate: this.state.dueDate, vendor: this.state.vendor}
        }
        console.log(formData);
        return formData;
    }

    validateFormData = (formData) => {
        let result = true;
        this.setState({errorData: {}});
        if (this.state.type === "FEES") {
            if (this.state.selectedStudents.length === 0) {
                console.log(this.state.studentUid)
                console.log("Empty")
                result = false
                this.setState({errorData: {studentUid: "Please select a student"}});
            }
        } else if (this.state.type === "SALARY") {
            if (_.isEmpty(this.state.teacherUid)) {
                result = false
                this.setState({errorData: {teacherUid: "Please select a teacher"}});
            }
        } else if (this.state.type === "INVOICE") {
            if (_.isEmpty(this.state.vendor)) {
                result = false
                this.setState({errorData: {vendor: "Please enter vendor name."}});
            }
        }
        return result;
    }


    handleSubmit(event) {
        event.preventDefault();
        let formData = this.populateFormData();
        let isFormDataValidated = this.validateFormData(formData);
        if (isFormDataValidated) {
            console.log("SUCCESS")
        } else {
            console.log("Failure")
            console.log(formData)
        }
        LedgerService.create(formData).then((response) => {
            console.log(response.data);
            if (response.responseStatus === "SUCCESS") {
                this.props.history.push("/admin/ledger/list");
            } else {
                this.setState({errorData: response.data});
                console.error("Error    ==>     " + response.message);
            }
        });
    };


    render() {
        return (
            <>
                <SimpleHeader
                    name="Create"
                    parentName="Lead"
                    heading="ClashMate"
                    // newBtnUrl="/admin/lead/create"
                    // newBtnText="Create Lead"
                />
                <Container className="mt--6" fluid>
                    <Card className="mb-4">
                        <CardHeader>
                            <h3 className="mb-0">Create Lead</h3>
                        </CardHeader>
                        <CardBody>
                            <Form role="form" onSubmit={this.handleSubmit}>
                                <LedgerForm
                                    onChange={this.handleChange}
                                    valueObj={this.state}
                                    errorObj={this.state.errorData}
                                    ledgerTypes={ledgerType}
                                    ledgerDurationTypes={ledgerDurationType}
                                    paymentTerms={paymentTerm}
                                    paymentStatus={paymentStatus}
                                    dueDate={this.state.dueDate}
                                    defaultType={() => ledgerType.get(0)}
                                    onSelectChange={this.handleSelectChange}
                                    selectedLead={this.state.leadType}
                                    handlePaymentTermSelectChange={this.handlePaymentTermSelectChange}
                                    displayNumberOfRecords={this.state.displayNumberOfRecords}
                                    handleTypeSelectChange={this.handleTypeSelectChange}
                                    handlePaymentStatusSelectChange={this.handlePaymentStatusSelectChange}
                                    handleLedgerDurationTypeSelectChange={this.handleLedgerDurationTypeSelectChange}
                                />
                                < LedgerFeeForm
                                    onChange={this.handleStudentSelectChange}
                                    valueObj={this.state}
                                    errorObj={this.state.errorData}
                                    defaultType={() => ledgerType.get(0)}
                                    onSelectChange={this.handleSelectChange}
                                    handleStudentSelectChange={this.handleStudentSelectChange}
                                    studentList={this.state.studentList}
                                    allSubjects={this.state.allSubjects}
                                    allSections={this.state.allSections}
                                    allCourses={this.state.allCourses}
                                    student={this.state.student}
                                    displayFee={this.state.displayFee}
                                />
                                < LedgerSalaryForm
                                    onChange={this.handleTeacherSelectChange}
                                    valueObj={this.state}
                                    errorObj={this.state.errorData}
                                    defaultType={() => ledgerType.get(0)}
                                    onSelectChange={this.handleSelectChange}
                                    handleTeacherSelectChange={this.handleTeacherSelectChange}
                                    teacherList={this.state.teacherList}
                                    allSubjects={this.state.allSubjects}
                                    allSections={this.state.allSections}
                                    allCourses={this.state.allCourses}
                                    teacher={this.state.teacher}
                                    displaySalary={this.state.displaySalary}
                                />
                                <LedgerInvoiceForm
                                    displayInvoice={this.state.displayInvoice}
                                    onChange={this.handleChange}
                                    valueObj={this.state}
                                    errorObj={this.state.errorData}
                                />
                                <div className="text-center">
                                    <Button className="my-4" color="info" type="submit">
                                        Submit
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
            </>
        );
    }
}

export default LedgerCreate;
