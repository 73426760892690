import React from "react";
import { Col, Row } from "reactstrap";
import FormInput from "../../../components/common/form/FormInput";
import FormReactSelect from "../../../components/common/form/FormReactSelect";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "assets/css/draft-js-style.css";

class ContentForm extends React.Component {
  showField() {
    return this.props.isEdit === true ? "" : "hidden";
  }

  showContentTextArea(){
    const { selectedContentType } = this.props;
    return selectedContentType &&
    (selectedContentType.key === "AUDIO" || selectedContentType.key === "VIDEO")
        ? ""
        : "hidden";

  }

  showContentEditor(){
    const { selectedContentType } = this.props;
    return selectedContentType &&
    (selectedContentType.key === "TEXT")
        ? ""
        : "hidden";

  }



  render() {
    let formRef = this;
    return (
      <>
        <Row>
          <Col md="4">
            <FormReactSelect
                selectKey={this.props.selectKey}
                isMulti={false}
                className="form-control-label"
                isSearchable={true}
                label="inputLabel.content.contentType"
                name={"contentType"}
                onChange={this.props.onContentTypeChange}
                optionValue={"value"}
                defaultValue={this.props.selectedContentType}
                optionList={this.props.contentTypes}
                optionLabel={"key"}
                placeHolder="inputPlaceHolder.content.contentType"
                errorMsg={this.props.errorObj.contentType || ""}
            />
          </Col>
          <Col md="4">
            <FormInput
              name="title"
              className="form-control-label"
              id="title"
              placeholder="inputPlaceHolder.content.title"
              type="text"
              onChange={this.props.onChange}
              label="inputLabel.content.title"
              value={this.props.valueObj.title || ""}
              errorMsg={this.props.errorObj.title || ""}
            />
          </Col>
          <Col md="4">
            <FormReactSelect
                selectKey={this.props.selectKey}
                isMulti={false}
                className="form-control-label"
                isSearchable={true}
                label="inputLabel.content.category"
                name={"category"}
                onChange={this.props.onSelectChange}
                optionValue={"value"}
                defaultValue={this.props.selectedCategory}
                optionList={this.props.contentCategories}
                optionLabel={"key"}
                placeHolder="inputPlaceHolder.content.category"
                errorMsg={this.props.errorObj.category || ""}
            />
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <FormReactSelect
                selectKey={this.props.selectKey}
                isMulti={true}
                className="form-control-label"
                isSearchable={true}
                label="inputLabel.content.articleTypes"
                name={"articleTypes"}
                onChange={this.props.onArticleTypeChange}
                optionValue={"value"}
                defaultValue={this.props.selectedArticleType}
                optionList={this.props.articleTypes}
                optionLabel={"key"}
                placeHolder="inputPlaceHolder.content.articleTypes"
                errorMsg={this.props.errorObj.articleTypes || ""}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={this.showContentTextArea()}>
              <FormInput
                  name="content"
                  className="form-control-label"
                  id="content"
                  placeholder="inputPlaceHolder.content.content"
                  type="textarea"
                  onChange={this.props.onChange}
                  label="inputLabel.content.content"
                  value={this.props.valueObj.content || ""}
                  errorMsg={this.props.errorObj.content || ""}
              />
            </div>
            <div className={this.showContentEditor()}>
              <br/>
              <br/>
              <br/>
              <br/>
              <Editor
                  editorState={this.props.valueObj.editorState}
                  toolbarClassName="toolbar-class"
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  onEditorStateChange={this.props.obj.handleContentChange}
                  toolbar={{
                    image: {
                      uploadCallback: this.props.uploadImageCallback,
                      alt: { present: true, mandatory: false },
                      inputAccept: 'image/*',
                      previewImage: true,
                    },
                  }}
              />
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default ContentForm;
