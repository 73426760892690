import React from "react";
import { Col, Row } from "reactstrap";
import FormInput from "../../../components/common/form/FormInput";
import FormReactSelect from "../../../components/common/form/FormReactSelect";

class ProjectForm extends React.Component {
  showField() {
    return this.props.isEdit === true ? "hidden" : "";
  }

  render() {
    return (
      <>
        <Row>
          <Col md="6">
            <FormInput
              name="name"
              className="form-control-label"
              id="name"
              placeholder="inputPlaceHolder.project.name"
              type="text"
              onChange={this.props.onChange}
              label="inputLabel.project.name"
              value={this.props.valueObj?.name || ""}
              errorMsg={this.props.errorObj?.name || ""}
            />
          </Col>
          <Col md="6">
            <FormReactSelect
                selectKey={this.props.attendeesSelectkey}
                isMulti={true}
                className="form-control-label"
                isSearchable={true}
                label="inputLabel.project.sharedWith"
                name={"sharedWith"}
                onChange={this.props.handleAttendeesChange}
                optionValue={"uniqueId"}
                defaultValue={this.props.selectedAttendees}
                optionList={this.props.attendesForSelection}
                optionLabel={"fullName"}
                placeHolder="inputPlaceHolder.project.sharedWith"
                errorMsg={this.props?.errorObj?.attendees || ""}
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default ProjectForm;
