import React from 'react';
import Step from './Step';
import {Input, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import FormInput from "../../../components/common/form/FormInput"; // Import the Step component

class Summary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            steps: [{ toDo: '', byWhen: '', status: 'Open', byWho: '' }],
        };
    }

    handleTextChange = (event) => {
        this.props.onTextChange(this.props.index, event.target.value);
    };

    handleSharedWithChange = (event) => {
        this.props.onSharedWithChange(this.props.index, event.target.value);
    };

    handleToDoChange = (stepIndex, value) => {
        const { steps } = this.state;
        steps[stepIndex].toDo = value;
        this.setState({ steps });
    };

    handleByWhenChange = (stepIndex, value) => {
        const { steps } = this.state;
        steps[stepIndex].byWhen = value;
        this.setState({ steps });
    };

    handleStatusChange = (stepIndex, value) => {
        const { steps } = this.state;
        steps[stepIndex].status = value;
        this.setState({ steps });
    };

    handleByWhoChange = (stepIndex, value) => {
        const { steps } = this.state;
        steps[stepIndex].byWho = value;
        this.setState({ steps });
    };

    addStep = () => {
        this.setState((prevState) => ({
            steps: [...prevState.steps, { toDo: '', byWhen: '', status: 'Open', byWho: '' }],
        }));
    };

    render() {
        const { text, sharedWith, index } = this.props;
        const { steps } = this.state;

        return (
            <>
                <Row>
                    <Col>
                        <label>Summary {index + 1}:</label>
                        <FormInput
                            name="summary"
                            className="form-control-label"
                            id="summary"
                            placeholder="inputPlaceHolder.meetingReport.summary"
                            type="textarea"
                            onChange={this.handleTextChange}
                            // label="inputLabel.meetingReport.summary"
                            // value={this.props.valueObj.address || ""}
                            // errorMsg={this.props.errorObj.address || ""}
                        />
                    </Col>
                    <Col>
                        <label>Share with:</label>
                        <input
                            type="text"
                            value={sharedWith}
                            onChange={this.handleSharedWithChange}
                            placeholder="Enter usernames separated by commas"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>


                        <h4>Next Steps:</h4>
                        {steps.map((step, stepIndex) => (
                            <Step
                                key={stepIndex}
                                index={stepIndex}
                                toDo={step.toDo}
                                byWhen={step.byWhen}
                                status={step.status}
                                byWho={step.byWho}
                                onToDoChange={this.handleToDoChange}
                                onByWhenChange={this.handleByWhenChange}
                                onStatusChange={this.handleStatusChange}
                                onByWhoChange={this.handleByWhoChange}
                            />
                        ))}

                        <button onClick={this.addStep}>Add Step</button>

                    </Col>
                </Row>
            </>
        );
    }
}

export default Summary;
