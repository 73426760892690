import React from "react";
import {Col, Row} from "reactstrap";
import FormInput from "../../../../components/common/form/FormInput";
import FormReactSelect from "../../../../components/common/form/FormReactSelect";

class LedgerForm extends React.Component {

    displayNumberOfRecords = () => {
        return this.props.displayNumberOfRecords === true ? "" : "hidden"
    };

    render() {
        return (
            <>
                <Row>
                    <Col md="4">
                        <FormReactSelect
                            selectKey={this.props.selectKey}
                            isMulti={false}
                            className="form-control-label"
                            isSearchable={true}
                            label="inputLabel.ledger.type"
                            name={"type"}
                            onChange={this.props.handleTypeSelectChange}
                            optionValue={"value"}
                            defaultValue={this.props.ledgerTypes[0]}
                            optionList={this.props.ledgerTypes}
                            optionLabel={"key"}
                            placeHolder="inputPlaceHolder.ledger.type"
                            errorMsg={this.props.errorObj.type || ""}
                        />
                    </Col>
                    <Col md="4">
                        <FormInput
                            name="amount"
                            className="form-control-label"
                            id="firstName"
                            placeholder="inputPlaceHolder.ledger.amount"
                            type="number"
                            onChange={this.props.onChange}
                            label="inputLabel.ledger.amount"
                            value={this.props.valueObj.amount || ""}
                            errorMsg={this.props.errorObj.amount || ""}
                        />
                    </Col>
                    <Col md="4">
                        <FormReactSelect
                            selectKey={this.props.selectKey}
                            isMulti={false}
                            className="form-control-label"
                            isSearchable={true}
                            label="inputLabel.ledger.paymentStatus"
                            name={"paymentStatus"}
                            onChange={this.props.handlePaymentStatusSelectChange}
                            optionValue={"value"}
                            defaultValue={this.props.paymentStatus[0]}
                            optionList={this.props.paymentStatus}
                            optionLabel={"key"}
                            placeHolder="inputPlaceHolder.ledger.paymentStatus"
                            errorMsg={this.props.errorObj.paymentStatus || ""}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md="4">
                        <FormReactSelect
                            selectKey={this.props.selectKey}
                            isMulti={false}
                            className="form-control-label"
                            isSearchable={true}
                            label="inputLabel.ledger.ledgerDurationType"
                            name={"ledgerDurationType"}
                            onChange={this.props.handleLedgerDurationTypeSelectChange}
                            optionValue={"value"}
                            defaultValue={this.props.ledgerDurationTypes[0]}
                            optionList={this.props.ledgerDurationTypes}
                            optionLabel={"key"}
                            placeHolder="inputPlaceHolder.ledger.ledgerDurationType"
                            errorMsg={this.props.errorObj.ledgerDurationType || ""}
                        />
                    </Col>
                    <Col md="4">
                        <FormReactSelect
                            selectKey={this.props.selectKey}
                            isMulti={false}
                            className="form-control-label"
                            isSearchable={true}
                            label="inputLabel.ledger.paymentTerm"
                            name={"paymentTerm"}
                            onChange={this.props.handlePaymentTermSelectChange}
                            optionValue={"value"}
                            defaultValue={this.props.paymentTerms[0]}
                            optionList={this.props.paymentTerms}
                            optionLabel={"key"}
                            placeHolder="inputPlaceHolder.ledger.paymentTerm"
                            errorMsg={this.props.errorObj.paymentTerm || ""}
                        />
                    </Col>
                    <Col md="4">
                        <FormInput
                            name="dueDate"
                            className="form-control-label"
                            id="dateEnd"
                            placeholder="inputPlaceHolder.ledger.dueDate"
                            type="date"
                            onChange={this.props.onChange}
                            label="inputLabel.ledger.dueDate"
                            value={this.props.dueDate || ""}
                            errorMsg={this.props.errorObj.dueDate || ""}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md="8">
                        <FormInput
                            name="description"
                            className="form-control-label"
                            id="description"
                            placeholder="inputPlaceHolder.ledger.description"
                            type="textarea"
                            onChange={this.props.onChange}
                            label="inputLabel.ledger.description"
                            value={this.props.valueObj.description || ""}
                            errorMsg={this.props.errorObj.description || ""}
                        />
                    </Col>
                    <Col md="4" className={this.props.displayNumberOfRecords === true ? "" : "hidden"}>
                        <FormInput
                            name="numberOfRecords"
                            className="form-control-label"
                            id="numberOfRecords"
                            placeholder="inputPlaceHolder.ledger.numberOfRecords"
                            type="number"
                            onChange={this.props.onChange}
                            label="inputLabel.ledger.numberOfRecords"
                            value={this.props.valueObj.numberOfRecords || ""}
                            errorMsg={this.props.errorObj.numberOfRecords || ""}
                        />
                    </Col>

                </Row>
            </>
        );
    }
}

export default LedgerForm;
