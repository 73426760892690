import SimpleHeader from "../../../../components/Headers/SimpleHeader";
import "file-upload-with-preview/dist/file-upload-with-preview.min.css";
import React from "react";
import {Button, Card, CardBody, CardHeader, Col, Container, Form, Row,} from "reactstrap";
import CourseSectionSubjectService from "../../../../services/courseSectionSubjectService";
import _ from "lodash";
import FormReactSelect from "../../../../components/common/form/FormReactSelect";
import CourseSectionService from "../../../../services/courseSectionService";
import UtilService from "../../../../services/utilService";
import FormInput from "../../../../components/common/form/FormInput";
import NotificationAlert from "react-notification-alert";
import AuthService from "../../../../services/authService";
import NoticeService from "../../../../services/noticeBoardService";
import AppRedirect from "../../../../util/appRedirect";

class CreateNotice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            upload: null,
            text: null,
            title: null,
            selectedSection: {},
            selectedCourse: {},
            selectedSubject: {},
            dateStart: null,
            dateEnd: null,
            errorData: {},
            courseSectionSubjectList: [],
            displayList: [],
            courseSectionList: [],
            mappedCoursesList: [],
            mappedSectionsList: [],
            mappedSubjectsList: [],
            mappedStudentsList: [],
            sectionSelectUUID: UtilService.uuidv4(),
            subjectSelectUUID: UtilService.uuidv4(),
            fileSelectUUID: UtilService.uuidv4(),
            dateList: [],
            studentList: []
        };
        this.handleCourseChange = this.handleCourseChange.bind(this);
        this.handleSectionChange = this.handleSectionChange.bind(this);
    }

    onChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
        console.log(this.state);
    }

    fileChange = (event) => {
        this.setState({files: [...event.target.files]})
    };

    handleSectionChange = (value) => {
        this.setState({subjectSelectUUID: UtilService.uuidv4()});
        this.setState({selectedSubject: {}});
        if (_.isNull(value)) {
            this.setState({selectedSection: {}});
        } else {
            this.setState({selectedSection: value});
        }
        this.setState({displayList: []});
        this.populateSubjects(this.state.selectedCourse, value);
    }

    handleCourseChange = (value) => {
        this.setState({sectionSelectUUID: UtilService.uuidv4()});
        this.setState({subjectSelectUUID: UtilService.uuidv4()});
        if (_.isNull(value)) {
            this.setState({selectedCourse: {}});
        } else {
            this.setState({selectedCourse: value});
        }
        this.setState({selectedSection: {}});
        this.setState({selectedSubject: {}});
        this.setState({mappedSectionsList: []});
        this.setState({displayList: []});
        this.populateSections(value);
    }

    handleSubjectChange = (value) => {
        if (_.isNull(value)) {
            this.setState({selectedSubject: {}});
        } else {
            this.setState({selectedSubject: value});
        }
        this.setState({date: null})
    };

    populateSections = (course) => {
        let sectionsList = _.filter(
            this.state.courseSectionList,
            (courseSection) => {
                return courseSection.course.uniqueId === course.uniqueId;
            }
        );
        let mappedSectionsList = _.uniqBy(_.map(sectionsList, "section"), "title");
        this.setState({
            mappedSectionsList: mappedSectionsList,
        });
    };

    populateSubjects = (course, section) => {
        let mappedCourseSectionSubjects = _.filter(
            this.state.courseSectionSubjectList,
            (courseSectionSubject) => {
                return (
                    courseSectionSubject.course.uniqueId === course.uniqueId &&
                    courseSectionSubject.section.uniqueId === section.uniqueId
                );
            }
        );

        let mappedSubjectsList = _.map(mappedCourseSectionSubjects, "subject");
        this.setState({mappedSubjectsList: mappedSubjectsList});
    };

    populateCourseSectionSubjects = async () => {
        await CourseSectionSubjectService.fetchCourseSectionSubjects().then(
            (res) => {
                this.setState({courseSectionSubjectList: res.data});
            }
        );
    };

    populateCourseSections = async () => {
        await CourseSectionService.fetchCourseSections().then((res) => {
            this.setState({courseSectionList: res.data});
            let mappedCoursesList = _.uniqBy(
                _.map(this.state.courseSectionList, "course"),
                "title"
            );
            this.setState({
                mappedCoursesList: mappedCoursesList,
            });
        });
    };

    createNotice = async () => {
        let self = this;
        let allOptionsNotSelected = _.isEmpty(this.state.text) && (_.isEmpty(this.state.files))
        if (allOptionsNotSelected === true) {
            console.error("All options are not selected!")
            this.notify(
                "error",
                "Error",
                "Cannot create empty notice!"
            );
        } else {
            const formData = new FormData();
            if (this.state.files !== null) {
                for (var i = 0; i < this.state.files.length; i++) {
                    formData.append('files', this.state.files[i]);
                }
            }
            formData.append('courseUID', this.state.selectedCourse.uniqueId ? this.state.selectedCourse.uniqueId : "");
            formData.append('sectionUID', this.state.selectedSection.uniqueId ? this.state.selectedSection.uniqueId : "");
            formData.append('subjectUID', this.state.selectedSubject.uniqueId ? this.state.selectedSubject.uniqueId : "");
            formData.append('text', this.state.text ? this.state.text : null);
            formData.append('title', this.state.title ? this.state.title : null);
            await NoticeService.create(formData).then(response => {
                if (response.responseStatus === "SUCCESS") {
                    this.setState({
                        text: '',
                        title: '',
                        files: [],
                        selectedSection: {},
                        selectedCourse: {},
                        selectedSubject: {}
                    });
                    this.notify(
                        response.responseStatus.toLowerCase(),
                        response.responseStatus,
                        response.message
                    );
                    console.log(response);
                    AppRedirect.redirectWithWait(self, `/${AuthService.getBaseURLByRole()}/notice/`, 2000);
                } else {
                    this.setState({errorData: response.data});
                    console.error("Error    ==>     " + response.message);
                }

            });

        }

    };

    notify = (type, title, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5,
        };
        this.refs.notificationAlert.notificationAlert(options);
    };

    componentDidMount() {
        this.populateCourseSectionSubjects();
        this.populateCourseSections();
    };


    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    render() {
        return (
            <>
                <SimpleHeader
                    parentName="Create"
                    name="Notice"
                    sectionName=""
                    heading="ClashMate"
                    newBtnText={"Notices"}
                    newBtnUrl={`/${AuthService.getBaseURLByRole()}/notice/`}
                />
                <div className="rna-wrapper">
                    <NotificationAlert ref="notificationAlert"/>
                </div>
                <Container className="mt--6" fluid>
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <Col sm="12">
                                            <h3 className="mb-0">Create Notice</h3>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form role="form">
                                        <Row>
                                            <Col sm="12">
                                                <FormInput
                                                    name="title"
                                                    className="form-control-label"
                                                    id="text"
                                                    placeholder="inputPlaceHolder.notice.create.title"
                                                    type="textarea"
                                                    onChange={this.onChange}
                                                    label="inputLabel.notice.create.title"
                                                    // value={this.props.valueObj.text || ""}
                                                    // errorMsg={this.props.errorObj.text || ""}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="12">
                                                <FormInput
                                                    name="text"
                                                    className="form-control-label"
                                                    id="text"
                                                    placeholder="inputPlaceHolder.notice.create.text"
                                                    type="textarea"
                                                    onChange={this.onChange}
                                                    label="inputLabel.notice.create.text"
                                                    // value={this.props.valueObj.text || ""}
                                                    // errorMsg={this.props.errorObj.text || ""}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl="12">
                                                <span className="h3">Add attachments</span>
                                                <FormInput
                                                    name="files"
                                                    className="form-control-label custom-file-input"
                                                    id="files"
                                                    placeholder="inputPlaceHolder.notice.files"
                                                    type="file"
                                                    onChange={this.fileChange}
                                                    label="Browse"
                                                    multiple={true}
                                                    key={this.state.fileSelectUUID}
                                                    // value={this.props.valueObj.firstName || ""}
                                                    // errorMsg={this.props.errorObj.firstName || ""}
                                                />
                                                {this.state.files.map(function (value, index) {
                                                    return (
                                                        <>
                                                            <span
                                                                className="badge badge-pill badge-primary">{value.name}</span>
                                                        </>
                                                    );
                                                })}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="12">
                                                <h1>&nbsp;</h1>

                                            </Col>
                                        </Row>
                                        <Row className="align-items-center">
                                            <Col sm="12">
                                                <h3 className="mb-0">Choose Visibility</h3>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="4">
                                                <FormReactSelect
                                                    isMulti={false}
                                                    className="form-control-label"
                                                    isSearchable={true}
                                                    name={"courseUid"}
                                                    placeHolder="inputPlaceHolder.notice.select.course"
                                                    onChange={this.handleCourseChange}
                                                    optionValue={"uniqueId"}
                                                    // value={this.state.selectedCourse}
                                                    optionList={this.state.mappedCoursesList}
                                                    optionLabel={"title"}
                                                    label="inputLabel.notice.select.course"
                                                    errorMsg={this.state.errorData.courseUid || ""}
                                                />
                                            </Col>
                                            <Col md="4">
                                                <FormReactSelect
                                                    selectKey={`section_select_${this.state.sectionSelectUUID}`}
                                                    isMulti={false}
                                                    className="form-control-label"
                                                    isSearchable={true}
                                                    label="inputLabel.notice.select.section"
                                                    name={"sectionUid"}
                                                    onChange={this.handleSectionChange}
                                                    optionValue={"uniqueId"}
                                                    optionList={this.state.mappedSectionsList}
                                                    optionLabel={"title"}
                                                    placeHolder="inputPlaceHolder.notice.select.section"
                                                    errorMsg={this.state.errorData.sectionUid || ""}
                                                />
                                            </Col>
                                            <Col md="4">
                                                <FormReactSelect
                                                    selectKey={`subject_select_${this.state.subjectSelectUUID}`}
                                                    isMulti={false}
                                                    className="form-control-label"
                                                    isSearchable={true}
                                                    label="inputLabel.notice.select.subject"
                                                    name={"subjectUid"}
                                                    onChange={this.handleSubjectChange}
                                                    optionValue={"uniqueId"}
                                                    optionList={this.state.mappedSubjectsList}
                                                    optionLabel={"title"}
                                                    placeHolder="inputPlaceHolder.notice.select.subject"
                                                    errorMsg={this.state.errorData.subjectUid || ""}
                                                />
                                            </Col>
                                        </Row>

                                        <div className="text-center">
                                            <Button className="my-4" color="info" type="button"
                                                    onClick={this.createNotice}>
                                                Create Notice
                                            </Button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    };
}

export default CreateNotice;
