/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// react library for routing
import { Link } from "react-router-dom";
// reactstrap components
import {
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Collapse,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Col,
    Row,
    Container,
    Card,
    CardHeader,
    CardBody,
    ListGroup,
    ListGroupItem,
    Carousel,
    CarouselIndicators,
    CarouselItem,
    CarouselCaption, CarouselControl
} from 'reactstrap';
// core components
import IndexNavbar from "components/Navbars/IndexNavbar.jsx";
import IndexHeader from "components/Headers/IndexHeader.jsx";
import AuthFooter from "components/Footers/AuthFooter.jsx";
import ContentService from "../services/xgContentService";
import _ from "lodash";
import CompaniesCarousel from "./CompaniesCarousel";
import ArticleCarousel from "./ArticleCarousel";
import VideoCarousel from "./VideoCarousel";
import AudioCarousel from "./AudioCarousel";
import UtilService from "../services/utilService";

class KnowledgeCentre extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapseSearchServices: false,
            collapseSearchArticles: false,
            completeContentList:[],
            searchList:[],
            articleKey: UtilService.uuidv4(),
            videoKey: UtilService.uuidv4(),
            audioKey: UtilService.uuidv4(),

        };
        this.filterByContentType = this.filterByContentType.bind(this);
    }

    componentDidMount() {
        ContentService.list().then((response) => {
            console.log(response);
            this.setState({completeContentList: response});
            this.setState({audio: this.filterByContentType(this.state.completeContentList, "AUDIO")});
            this.setState({video: this.filterByContentType(this.state.completeContentList, "VIDEO")});
            this.setState({text: this.filterByContentType(this.state.completeContentList, "TEXT")});
            console.log(this.state)
            this.toggleSearchArticles();
            this.setState({
                articleKey: UtilService.uuidv4(),
                videoKey: UtilService.uuidv4(),
                audioKey: UtilService.uuidv4(),
            })
        });
    }

    filterByContentType = (data, type) => {
        return _.filter(data, { contentType: type });
    };

    toggleSearchServices = (event) => {
        event.preventDefault();
        this.props.history.push("/");
    }

    toggleSearchArticles = () => {
        this.setState({
            collapseSearchArticles: !this.state.collapseSearchArticles,
            collapseSearchServices: false // Close the services section when articles is toggled
        });
    }

    displaySearchArticles = () =>{
        return this.state.searchList.length === 0 ?"DIsplay Search ARticles" : <></>
    }

    onSearchInputChange = (event) => {
        let term = event.target.value;
        this.setState({term:event.target.value})
        console.log(this.state.term)
    }

    onSearch = event => {
        const filteredItems = _.filter(this.state.completeContentList, (item) => {
            return _.includes(item.title.toLowerCase(), this.state.term.toLowerCase()) ||
                _.includes(item.category.toLowerCase(), this.state.term.toLowerCase());
        });
        console.log(filteredItems)
        this.setState({audio: this.filterByContentType(filteredItems, "AUDIO")});
        this.setState({video: this.filterByContentType(filteredItems, "VIDEO")});
        this.setState({text: this.filterByContentType(filteredItems, "TEXT")});
        this.setState({
            articleKey: UtilService.uuidv4(),
            videoKey: UtilService.uuidv4(),
            audioKey: UtilService.uuidv4(),
        })
    }


    render() {
        let completeContentList = this.state.completeContentList;
        return (
            <div>
                {/* Navbar */}
                <Navbar color="white" light expand="md" className={"bg-white"}>
                    <NavbarBrand href="/"><img height={"68px"} width={"170px"} src={require("assets/img/brand/xareLogo.png")}/></NavbarBrand>
                    <Nav className="ml-auto" navbar>
                        <NavItem>
                            <Button outline color="default"  className="mb-3"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        this.props.history.push("/");
                                    }}
                            >
                                Services
                            </Button>
                            <>
                                {localStorage.getItem("userId") ? (
                                    <Button
                                        color="default"
                                        className="mb-3"
                                        outline
                                        onClick={(event) => {
                                            event.preventDefault();
                                            this.props.history.push("/admin/dashboard"); // Navigate to CRM if logged in
                                        }}
                                    >
                                        Switch to CRM
                                    </Button>
                                ) : (
                                    <Button
                                        color="default"
                                        className="mb-3"
                                        outline
                                        onClick={(event) => {
                                            event.preventDefault();
                                            this.props.history.push("/auth/login"); // Navigate to login if not logged in
                                        }}
                                    >
                                        Login
                                    </Button>
                                )}
                            </>

                        </NavItem>
                    </Nav>
                </Navbar>

                {/* Hero Banner */}
                <div className="hero-banner">
                    <Container fluid>
                        <Row>
                            <Col>
                                <div className="hero-banner-content">
                                    <p>A collaborative approach to </p>
                                    <h1>International Expansion</h1>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <br/>
                <br/>
                <br/>
                {/* Collapsible Sections */}
                <Container className={"mt-10"}>
                    {/* Search Services */}
                    <div className="text-center">
                        <Button color="default" onClick={this.toggleSearchServices} className="mb-3">
                            Search Services
                        </Button>
                        {/* Search Articles */}
                        <Button color="grey" onClick={this.toggleSearchArticles} className="mb-3">
                            Search Articles
                        </Button>
                    </div>
                    <Collapse isOpen={this.state.collapseSearchServices}>
                    </Collapse>


                    <Collapse isOpen={this.state.collapseSearchArticles}>
                        <Form>
                            <FormGroup>
                                <Label for="searchText">Search Article</Label>
                                <Input onChange={this.onSearchInputChange} type="text" name="searchText" id="searchText" />
                            </FormGroup>
                            <div className="text-center">
                                <Button color="danger" outline onClick={this.onSearch} type="button">Submit</Button>
                            </div>
                        </Form>
                    </Collapse>
                </Container>


                <ArticleCarousel
                    key={this.state.articleKey}
                    items={this.state?.text}
                />
                <VideoCarousel
                    key={this.state.videoKey}
                    items={this.state?.video}
                />
                <AudioCarousel
                    key={this.state.audioKey}
                    items={this.state?.audio}
                />

                <footer className="py-5" id="footer-main">
                    <Container>
                        <Row className="align-items-center justify-content-xl-between">
                            <Col xl="6">
                                <div className="copyright text-center text-xl-left text-muted">
                                    © {new Date().getFullYear()}{" "}
                                    <a
                                        className="font-weight-bold ml-1"
                                        href="https://sekim-international.com/"
                                        target="_blank"
                                    >
                                        Sekim International
                                    </a>
                                </div>
                            </Col>
                            <Col xl="6">
                                <Nav className="nav-footer justify-content-center justify-content-xl-end">
                                    <NavItem>
                                        <NavLink
                                            href="https://xaregrowth.com"
                                            target="_blank"
                                        >
                                            XareGrowth
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            href=""
                                            target="_blank"
                                        >
                                            About Us
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            href=""
                                            target="_blank"
                                        >
                                            Blog
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            href=""
                                            target="_blank"
                                        >
                                            License
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </div>
        );
    }
}

export default KnowledgeCentre;
