/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {Button, Card, CardBody, CardHeader, Container, Form,} from "reactstrap";
import LeadForm from "./Form";
import LeadService from "../../../services/leadService";
import _ from "lodash";
import UtilService from "../../../services/utilService";
import {leadTypes} from "../../../util/leadTypes"

class LeadEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorData: {},
      selectKey: UtilService.uuidv4()
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  componentDidMount() {
    const {uniqueId} = this.props.match.params;
    LeadService.fetch(uniqueId).then((response) => {
      console.log(response.data);
      this.setState({currentLead: {key: response.data.leadType, value: response.data.leadType}})
      this.setState(response.data);
      this.setState({selectKey: UtilService.uuidv4()});
      this.setState({uniqueId: uniqueId});
    });

  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
    console.log(this.state);
  }

  handleSelectChange(valueObj) {
    if (_.isNull(valueObj)) {
      this.setState({leadType: {}});
    } else {
      this.setState({leadType: valueObj.value});
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    LeadService.update(this.state).then((response) => {
      console.log(response.data);
      if (response.responseStatus === "SUCCESS") {
        this.props.history.push("/admin/lead/list");
      } else {
        this.setState({errorData: response.data});
        console.error("Error    ==>     " + response.message);
      }
    });
  }

  render() {
    return (
        <>
          <SimpleHeader
              name="Edit"
              parentName="Lead"
              heading="ClashMate"
              // newBtnUrl="/admin/section/create"
              // newBtnText="Create Section"
          />
          <Container className="mt--6" fluid>
            <Card className="mb-4">
              <CardHeader>
                <h3 className="mb-0">Edit Lead</h3>
              </CardHeader>
              <CardBody>
                <Form role="form" onSubmit={this.handleSubmit}>
                  <LeadForm
                      onChange={this.handleChange}
                      valueObj={this.state}
                      errorObj={this.state.errorData}
                      leadTypes={leadTypes}
                      onSelectChange={this.handleSelectChange}
                      selectedLead={this.state.currentLead}
                      selectKey={`select_lead_${this.state.selectKey}`}
                  />
                  <div className="text-center">
                    <Button className="my-4" color="info" type="submit">
                      Update
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Container>
        </>
    );
  }
}

export default LeadEdit;
