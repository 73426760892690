import React from "react";
import {Card, CardHeader, CardImg, Col, Row} from "reactstrap";
import FormReactSelect from "../../../../components/common/form/FormReactSelect";
import Button from "reactstrap/lib/Button";
import CardBody from "reactstrap/lib/CardBody";
import Moment from "react-moment";
import Badge from "reactstrap/lib/Badge";
import _ from "lodash";

class LedgerSalaryForm extends React.Component {

    display = () => {
        return this.props.displaySalary === true ? "" : "hidden"
    };

    displayTeacherData = () => {
        return _.isEmpty(this.props.teacher) === true ? "hidden" : ""
    };

    render() {
        return (
            <>
                <div className={this.display()}>
                    <Row>
                        <Col md="8">
                            <FormReactSelect
                                selectKey={this.props.selectKey}
                                isMulti={true}
                                className="form-control-label"
                                isSearchable={true}
                                label="inputLabel.ledger.teacher.select"
                                name={"teacherUid"}
                                onChange={this.props.handleTeacherSelectChange}
                                optionValue={"uniqueId"}
                                optionList={this.props.teacherList}
                                optionLabel={"fullName"}
                                placeHolder="inputPlaceHolder.ledger.teacher.select"
                                errorMsg={this.props.errorObj.teacherUid || ""}
                            />
                            <Row>
                                <div className={this.displayTeacherData()}>
                                    <p className="h4">Courses</p>
                                    <Col md="12">
                                        {_.map(this.props.allCourses, course => {
                                            return (
                                                <Badge key={course.uniqueId} color="info" pill>{course.title} </Badge>);
                                        })}
                                    </Col>
                                    <hr/>
                                    <p className="h4">Sections</p>
                                    <Col md="12">
                                        {_.map(this.props.allSections, section => {
                                            return (
                                                <Badge key={section.uniqueId} color="success"
                                                       pill>{section.title} </Badge>);
                                        })}
                                    </Col>
                                    <hr/>
                                    <p className="h4">Subjects</p>
                                    <Col md="12">
                                        {_.map(this.props.allSubjects, subject => {
                                            return (
                                                <Badge key={subject.uniqueId} color="success"
                                                       pill>{subject.title} </Badge>);
                                        })}
                                    </Col>
                                    <hr/>
                                </div>
                            </Row>
                        </Col>
                        <Col md="4" className={this.displayTeacherData()}>
                            <Card className="card-profile">
                                <CardImg
                                    alt="..."
                                    src={require("assets/img/theme/img-1-1000x600.jpg")}
                                    top
                                />
                                <Row className="justify-content-center">
                                    <Col className="order-lg-2" lg="3">
                                        <div className="card-profile-image">
                                            <a href="#pablo" onClick={e => e.preventDefault()}>
                                                <img
                                                    alt="..."
                                                    className="rounded-circle"
                                                    src={require("assets/img/theme/team-4.jpg")}
                                                />
                                            </a>
                                        </div>
                                    </Col>
                                </Row>
                                <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                                    <div className="d-flex justify-content-between">
                                        <Button
                                            className="mr-4"
                                            color="info"
                                            href="#pablo"
                                            onClick={e => e.preventDefault()}
                                            size="sm"
                                        >
                                            Connect
                                        </Button>
                                        <Button
                                            className="float-right"
                                            color="default"
                                            href="#pablo"
                                            onClick={e => e.preventDefault()}
                                            size="sm"
                                        >
                                            Message
                                        </Button>
                                    </div>
                                </CardHeader>
                                <CardBody className="pt-0">
                                    <Row>
                                        <div className="col">
                                            <div className="card-profile-stats d-flex justify-content-center">
                                                <div>
                                                    <span className="heading">22</span>
                                                    <span className="description">Friends</span>
                                                </div>
                                                <div>
                                                    <span className="heading">10</span>
                                                    <span className="description">Photos</span>
                                                </div>
                                                <div>
                                                    <span className="heading">89</span>
                                                    <span className="description">Comments</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                    <div className="text-center">
                                        <h5 className="h3">
                                            {this.props.teacher.fullName}
                                            <span className="font-weight-light">, <Moment fromNow
                                                                                          ago>{this.props.teacher.dateOfBirth}</Moment></span>
                                        </h5>
                                        <div className="h5 font-weight-300">
                                            <i className="ni location_pin mr-2"/>
                                            {this.props.teacher.emailAddress}
                                        </div>
                                        <div className="h5 mt-4">
                                            <i className="ni business_briefcase-24 mr-2"/>
                                            Gender {this.props.teacher.gender}
                                        </div>
                                        <div>
                                            <i className="ni education_hat mr-2"/>
                                            Date of Birth {this.props.teacher.dateOfBirth}
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default LedgerSalaryForm;
