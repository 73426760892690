/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {Button, Card, CardBody, CardHeader, Container, Form} from "reactstrap";
import ParentService from "../../../services/parentService";
import ParentForm from "./Form";
import ProjectService from "../../../services/projectService";
import ProjectForm from "./Form";
import NotificationAlert from "react-notification-alert";
import AdminService from "../../../services/adminService";
import UtilService from "../../../services/utilService";
import _ from "lodash";

class ProjectCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorData: {},
            attendeesSelectKey :UtilService.uuidv4(),
            attendesForSelection : []
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleAttendeesChange = this.handleAttendeesChange.bind(this);
    }

    componentDidMount() {
        AdminService.list().then((response) => {
            console.log(response);
            this.setState({attendesForSelection: response.data, attendeesSelectKey :UtilService.uuidv4() });
        });
        console.log(this.state.attendesForSelection)
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
        console.log(this.state);
    }

    handleAttendeesChange = (valueObj,event) => {
        if (_.isNull(valueObj)) {
            this.setState({selectedAttendees: []});
        } else {
            this.setState({selectedAttendees: valueObj});
            const attendees = _.map(valueObj, "uniqueId")
            this.setState({sharedWith: attendees});
        }
    }


    handleSubmit(event) {
        event.preventDefault();
        ProjectService.create(this.state).then(response => {
            console.log(response.data);
            if (response.responseStatus === "SUCCESS") {
                this.props.history.push("/admin/project/list");
            } else {
                this.setState({errorData: response.data});
                console.error("Error    ==>     " + response.message);
            }
        });
    }

    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };

    notify = (type, title, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5,
        };
        this.refs.notificationAlert.notificationAlert(options);
    }

    render() {
        return (
            <>
                <div className="rna-wrapper">
                    <NotificationAlert ref="notificationAlert"/>
                </div>
                <SimpleHeader
                    name="Create"
                    parentName="Project"
                    heading="XareGrwoth"
                    newBtnUrl="/admin/project/list"
                    newBtnText="List All Project"
                />
                <Container className="mt--6" fluid>
                    <Card className="mb-4">
                        <CardHeader>
                            <h3 className="mb-0">Create Project</h3>
                        </CardHeader>
                        <CardBody>
                            <Form role="form" onSubmit={this.handleSubmit}>
                                <ProjectForm
                                    onChange={this.handleChange}
                                    valueObj={this.state}
                                    errorObj={this.state.errorData}
                                    // isEdit={false}
                                    attendeesSelectkey={this.state.attendeesSelectKey}
                                    handleAttendeesChange={this.handleAttendeesChange}
                                    selectedAttendees={this.state.selectedAttendees}
                                    attendesForSelection={this.state.attendesForSelection}
                                />
                                <div className="text-center">
                                    <Button className="my-4" color="info" type="submit">
                                        Submit
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
            </>
        );
    }
}

export default ProjectCreate;
