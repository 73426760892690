import React from "react";
import {Col, Row} from "reactstrap";
import FormInput from "../../../components/common/form/FormInput";

class LeadRemarkForm extends React.Component {
    render() {
        return (
            <>
                <Row>
                    <Col md="12">
                        <FormInput
                            name="remark"
                            className="form-control-label"
                            id="remark"
                            placeholder="inputPlaceHolder.leadRemark.remark"
                            type="textarea"
                            onChange={this.props.onChange}
                            label="inputLabel.leadRemark.remark"
                            value={this.props.valueObj.remark || ""}
                            errorMsg={this.props.errorObj.remark || ""}
                        />
                    </Col>
                </Row>
            </>
        );
    }
}

export default LeadRemarkForm;
