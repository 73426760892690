import React from 'react';

class Step extends React.Component {
    handleToDoChange = (event) => {
        this.props.onToDoChange(this.props.index, event.target.value);
    };

    handleByWhenChange = (event) => {
        this.props.onByWhenChange(this.props.index, event.target.value);
    };

    handleStatusChange = (event) => {
        this.props.onStatusChange(this.props.index, event.target.value);
    };

    handleByWhoChange = (event) => {
        this.props.onByWhoChange(this.props.index, event.target.value);
    };

    render() {
        const { toDo, byWhen, status, byWho, index } = this.props;

        return (
            <div className="step">
                <input
                    type="text"
                    value={toDo}
                    onChange={this.handleToDoChange}
                    placeholder="To do"
                />

                <input
                    type="date"
                    value={byWhen}
                    onChange={this.handleByWhenChange}
                />

                <select value={status} onChange={this.handleStatusChange}>
                    <option value="Open">Open</option>
                    <option value="Completed">Completed</option>
                </select>

                <input
                    type="text"
                    value={byWho}
                    onChange={this.handleByWhoChange}
                    placeholder="Assign Xaregrowth User"
                />
            </div>
        );
    }
}

export default Step;
