import "assets/css/draft-js-style.css";
import React from "react";
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {Col, Row} from "reactstrap";
import Badge from "reactstrap/lib/Badge";
import Label from "reactstrap/lib/Label";
import FormInput from "../../../components/common/form/FormInput";

class NotificationForm extends React.Component {
    showField() {
        return this.props.isEdit === true ? "hidden" : "";
    }

    render() {
        let obj = this;
        return (
            <>
                <Row>
                    <Col
                        md="12"
                        className={this.props.valueObj.isSubjectHidden ? "hidden" : ""}
                    >
                        <FormInput
                            name="subject"
                            className="form-control-label"
                            id="subject"
                            placeholder="inputPlaceHolder.notification.notification"
                            type="text"
                            onChange={this.props.onChange}
                            label="inputLabel.notification.notification"
                            value={this.props.valueObj.subject || ""}
                            errorMsg={this.props.errorObj.subject || ""}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md="4">
                        <Label className="form-control-label">
                            <input
                                id="isEnabled"
                                name="isEnabled"
                                type="checkbox"
                                onClick={this.props.onChange}
                                defaultChecked={this.props.valueObj.isEnabled}
                            />
                            &nbsp;Enabled?
                        </Label>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <Row>
                            <Col md="8">
                                <Label className="form-control-label">Notification Content</Label>
                            </Col>
                            <Col
                                md="4"
                                className={this.props.valueObj.isCountedMedium ? "" : "hidden"}
                            >
                                <p className="h4 text-right">
                                    ({this.props.valueObj.characterCount} /{" "}
                                    {Math.ceil(this.props.valueObj.characterCount / 160)})
                                </p>
                            </Col>
                        </Row>
                        <Editor
                            toolbarHidden={this.props.valueObj.isToolbarHidden}
                            editorState={this.props.valueObj.editorState}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            onEditorStateChange={this.props.obj.handleContentChange}
                        />
                        <div className={this.props.valueObj.keywords ? "help-block" : "hidden"}>
                            Keywords:&nbsp;
                            {this.props.valueObj.keywords &&
                            this.props.valueObj.keywords.map(function (value, index) {
                                return (
                                    <Badge
                                        key={index}
                                        style={{cursor: "pointer"}}
                                        color="primary"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            obj.props.sendTextToEditor(e.currentTarget.innerHTML);
                                        }}
                                    >
                                        ###{value}###
                                    </Badge>
                                );
                            })}
                        </div>
                        <div
                            className={this.props.valueObj.isCountedMedium ? "help-block" : "hidden"}
                        >
                            When you send an SMS in English the credit limit is{" "}
                            <span className="h4">160</span> characters.
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
}

export default NotificationForm;
