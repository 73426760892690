/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import MappingStudentList from "../student/studentParent/MappingStudentList";
import React from "react";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container, DropdownItem, DropdownMenu, DropdownToggle, Form,
    FormGroup, Modal,
    Row, Table, UncontrolledDropdown,
} from "reactstrap";
import ParentService from "../../../services/parentService";
import ProjectService from "../../../services/projectService";
import SummaryForm from "../summary/Form";
import {companyType} from "../../../util/companyType";
import CompanyForm from "../company/Form";
import _ from "lodash";
import CompanyService from "../../../services/companyService";
import NotificationAlert from "react-notification-alert";
import AttachCompanyForm from "./AttachCompanyForm";
import UtilService from "../../../services/utilService";
import ContactService from "../../../services/contactService";
import ProjectCompanyService from "../../../services/projectCompanyService";

class ProjectDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projectData: {sharedWith : []},
            errorData: {},
            selectKey: UtilService.uuidv4(),
            contactKey: UtilService.uuidv4(),
            contactList: [],
            lastContact: "",
            nextContact: "",
            filteredCompanies: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleCompanySubmit = this.handleCompanySubmit.bind(this);
        this.handleCompanyTypeChange = this.handleCompanyTypeChange.bind(this);
        this.handleAttachCompanySubmit = this.handleAttachCompanySubmit.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handlePotentialChange = this.handlePotentialChange.bind(this);
        this.handleInterestChange = this.handleInterestChange.bind(this);
        this.handlePriorityChange = this.handlePriorityChange.bind(this);
        this.handleMainContactChange = this.handleMainContactChange.bind(this);
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
        console.log(this.state);
    }

    handleCompanyChange = (valueObj, event) => {
        console.log(valueObj);
        if (_.isNull(valueObj)) {
            this.setState({selectedCompany: {}, companyUID: ""});
        } else {
            this.setState({selectedCompany: valueObj, companyUID: valueObj.uniqueId});
            ContactService.fetchAllByCompany(valueObj.uniqueId).then(response => {
                this.setState({contactList: response, contactKey: UtilService.uuidv4(), mainContact: ""})
            })
        }
    };

    async componentDidMount() {
        const {uniqueId} = this.props.match.params;
        this.setState({projectUID : uniqueId})
        ProjectService.fetch(uniqueId).then((response) => {
            console.log(response.data);
            this.setState({projectData: response.data});
        });
        CompanyService.list().then(response => {
            this.setState({allCompanies: response});
            console.log(this.state.allCompanies)
            ProjectCompanyService.list().then(response => {
                let projectCompanies = _.filter(response, projectCompany => projectCompany.projectUID === uniqueId)
                console.log(projectCompanies)
                const uniqueCompanyUIDs = _.uniq(_.map(projectCompanies, 'companyUID'));
                console.log(uniqueCompanyUIDs)
                const filteredCompanies = _.filter(this.state.allCompanies, (company) => _.includes(uniqueCompanyUIDs, company.uniqueId));
                this.setState({filteredCompanies : filteredCompanies})
                console.log(filteredCompanies)
            });
        })
        this.setState({selectKey: UtilService.uuidv4()});
        console.log(this.state);
    }

    handleCompanySubmit(event) {
        event.preventDefault()
        console.log(this.state);
        CompanyService.create(this.state).then(response => {
            console.log(response.data);
            this.notify(
                response.responseStatus.toLowerCase(),
                response.responseStatus,
                response.message
            );
            if (response.responseStatus === "SUCCESS") {
                this.toggleModal("companyModal")
            } else {
                this.setState({errorData: response.data});
                console.error("Error    ==>     " + response.message);
            }
        });
    }

    handleAttachCompanySubmit(event) {
        event.preventDefault()
        console.log(this.state);
        ProjectCompanyService.create(this.state).then(response => {
            console.log(response.data);
            this.notify(
                response.responseStatus.toLowerCase(),
                response.responseStatus,
                response.message
            );
            if (response.responseStatus === "SUCCESS") {
                this.toggleModal("attachCompanyModal")
            } else {
                this.setState({errorData: response.data});
                console.error("Error    ==>     " + response.message);
            }
        });

    }

    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };

    handleCompanyTypeChange = (valueObj, event) => {
        if (_.isNull(valueObj)) {
            this.setState({companyTypeList: []});
        } else {
            this.setState({companyTypeList: valueObj});
            const keys = _.map(valueObj, "key")
            this.setState({companyTypeList: keys});
        }
    };

    notify = (type, title, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5,
        };
        this.refs.notificationAlert.notificationAlert(options);
    }

    handlePotentialChange(valueObj) {
        if (_.isNull(valueObj)) {
            this.setState({selectedPotential: {}, potential: ""});
        } else {
            this.setState({selectedPotential: valueObj, potential: valueObj.key});
        }
    }

    handleMainContactChange(valueObj) {
        if (_.isNull(valueObj)) {
            this.setState({selectedMainContact: {}, mainContact: ""});
        } else {
            this.setState({selectedMainContact: valueObj, mainContact: valueObj.uniqueId});
        }
    }

    handleInterestChange(valueObj) {
        if (_.isNull(valueObj)) {
            this.setState({selectedInterest: {}, interest: ""});
        } else {
            this.setState({selectedInterest: valueObj, interest: valueObj.key});
        }
    }

    handlePriorityChange(valueObj) {
        if (_.isNull(valueObj)) {
            this.setState({selectedPriority: {}, priority: ""});
        } else {
            this.setState({selectedPriority: valueObj, priority: valueObj.key});
        }
    }


    render() {
        const {projectData} = this.state;
        let obj = this;
        return (
            <>
                <div className="rna-wrapper">
                    <NotificationAlert ref="notificationAlert"/>
                </div>
                <SimpleHeader
                    name="Details"
                    parentName="Project"
                    heading="XareGrowth"
                    newBtnUrl="/admin/project/list"
                    newBtnText="List All Project"
                />
                <Container className="mt--6" fluid>
                    <Card className="mb-4">
                        <CardHeader>
                            <h3 className="mb-0">{projectData?.name}</h3>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols1Input"
                                        >
                                            Project Name
                                        </label>
                                        <p>{projectData?.name}</p>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols2Input"
                                        >
                                            Shared With
                                        </label>
                                        <p>
                                            {projectData.sharedWith.map((value, index) => {
                                                return <Badge size="lg"  key={UtilService.uuidv4()} color="primary">{value?.fullName}</Badge>
                                            })}
                                        </p>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col md="2">
                                    <Button
                                        outline
                                        color="default"
                                        type="button"
                                        onClick={() => this.toggleModal("companyModal")}
                                    >
                                        New Company
                                    </Button>
                                    <Modal
                                        className="modal-dialog-centered"
                                        size="lg"
                                        isOpen={this.state.companyModal}
                                        toggle={() => this.toggleModal("companyModal")}
                                    >
                                        <div className="modal-body p-0">
                                            <Card className="bg-secondary shadow border-0">
                                                <CardHeader className="bg-transparent pb-5">
                                                    <div className="text-muted text-center mt-2 mb-3">
                                                        <small>Create new Company</small>
                                                    </div>
                                                </CardHeader>
                                                <CardBody className="px-lg-5 py-lg-5">
                                                    <Form role="form" onSubmit={this.handleCompanySubmit}>


                                                        <CompanyForm
                                                            onChange={this.handleChange}
                                                            valueObj={this.state}
                                                            errorObj={this.state.errorData}
                                                            isEdit={false}
                                                            companyType={companyType}
                                                            onCompanyTypeChange={this.handleCompanyTypeChange}
                                                        />
                                                        <div className="text-center">
                                                            <Button className="my-4" color="info" type="submit">
                                                                Submit
                                                            </Button>
                                                        </div>
                                                    </Form>
                                                </CardBody>
                                            </Card>
                                        </div>
                                    </Modal>
                                </Col>
                                <Col md="2">
                                    <Button
                                        outline
                                        color="default"
                                        type="button"
                                        onClick={() => this.toggleModal("attachCompanyModal")}
                                    >
                                        Add Company to Project
                                    </Button>
                                    <Modal
                                        className="modal-dialog-centered"
                                        size="lg"
                                        isOpen={this.state.attachCompanyModal}
                                        toggle={() => this.toggleModal("attachCompanyModal")}
                                    >
                                        <div className="modal-body p-0">
                                            <Card className="bg-secondary shadow border-0">
                                                <CardHeader className="bg-transparent pb-5">
                                                    <div className="text-muted text-center mt-2 mb-3">
                                                        <small>Attach Company Details</small>
                                                    </div>
                                                </CardHeader>
                                                <CardBody className="px-lg-5 py-lg-5">
                                                    <Form role="form" onSubmit={this.handleAttachCompanySubmit}>


                                                        <AttachCompanyForm
                                                            onChange={this.handleChange}
                                                            valueObj={this.state}
                                                            errorObj={this.state.errorData}
                                                            isEdit={false}
                                                            companyType={companyType}
                                                            onCompanyTypeChange={this.handleCompanyTypeChange}

                                                            onCompanyChange={this.handleCompanyChange}
                                                            selectedCompany={this.state.selectedCompany}
                                                            companies={this.state.allCompanies}
                                                            selectedPriority={this.state.selectedPriority}
                                                            handlePriorityChange={this.handlePriorityChange}
                                                            selectedPotential={this.state.selectedPotential}
                                                            handlePotentialChange={this.handlePotentialChange}
                                                            selectedInterest={this.state.selectedInterest}
                                                            handleInterestChange={this.handleInterestChange}
                                                            contactKey={this.state.contactKey}
                                                            contacts={this.state.contactList}
                                                            handleMainContactChange={this.handleMainContactChange}
                                                            selectedMainContact={this.selectedMainContact}


                                                        />
                                                        <div className="text-center">
                                                            <Button className="my-4" color="info" type="submit">
                                                                Submit
                                                            </Button>
                                                        </div>
                                                    </Form>
                                                </CardBody>
                                            </Card>
                                        </div>
                                    </Modal>
                                </Col>
                                <Col md="2">
                                    <Button
                                        outline
                                        color="default"
                                        type="button"
                                        onClick={(event) => {
                                            obj.props.history.push(
                                                `/admin/create`
                                            );
                                        }}
                                    >
                                        New User
                                    </Button>

                                </Col>
                                <Col md="2">
                                    <Button
                                        outline
                                        color="default"
                                        type="button"
                                        onClick={(event) => {
                                            obj.props.history.push(
                                                `/admin/project/create`
                                            );
                                        }}
                                    >
                                        New Project
                                    </Button>
                                </Col>
                                <Col md="2">
                                    <Button
                                        outline
                                        color="default"
                                        type="button"
                                        onClick={(event) => {
                                            obj.props.history.push(
                                                `/admin/contact/create`
                                            );
                                        }}
                                    >
                                        New Contact
                                    </Button>
                                </Col>
                            </Row>

                        </CardBody>
                    </Card>
                </Container>
                <br/>
                <br/>
                <br/>
                <br/>
                <Container className="mt--6" fluid>
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Companies List</h3>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Table className="align-items-center table-flush" responsive>
                                        <thead className="thead-light">
                                        <tr>
                                            <th className="sort" data-sort="name" scope="col">
                                                #
                                            </th>
                                            <th className="sort" data-sort="budget" scope="col">
                                                Date Created
                                            </th>
                                            <th className="sort" data-sort="status" scope="col">
                                                Legal Name
                                            </th>
                                            <th className="sort" data-sort="status" scope="col">
                                                Country
                                            </th>
                                            <th className="sort" data-sort="status" scope="col">
                                                Website
                                            </th>
                                            <th className="sort" data-sort="status" scope="col">
                                                Company Types
                                            </th>
                                            <th className="sort" data-sort="status" scope="col">
                                                Address
                                            </th>
                                            <th className="sort" data-sort="status" scope="col">
                                                Tax Ref Number
                                            </th>
                                            <th scope="col"/>
                                        </tr>
                                        </thead>
                                        <tbody className="list">
                                        {this.state.filteredCompanies.map(function (value, index) {
                                            return (
                                                <tr key={index}>
                                                    <td className="budget">{index + 1}</td>
                                                    <td className="budget">{value.dateCreated}</td>
                                                    <td className="budget">{value.legalName}</td>
                                                    <td className="budget">{value.country}</td>
                                                    <td className="budget">{value.website}</td>
                                                    <td className="budget">{value.companyTypeList && value.companyTypeList.map(function (type, index) {
                                                        return <h4 key={index} className="badge badge-lg badge-primary">{type}</h4>
                                                    })}</td>
                                                    <td className="budget">{value.address}</td>
                                                    <td className="budget">{value.taxRefNumber}</td>
                                                    <td className="text-right">
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle
                                                                color=""
                                                                size="sm"
                                                                className="btn-icon-only text-light"
                                                            >
                                                                <i className="fas fa-ellipsis-v"/>
                                                            </DropdownToggle>
                                                            <DropdownMenu
                                                                className="dropdown-menu-arrow"
                                                                right
                                                            >
                                                                <DropdownItem
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        obj.displayContacts(value.uniqueId);
                                                                    }}
                                                                >
                                                                    View All Contacts
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        obj.props.history.push(
                                                                            `/admin/company/show/${value.uniqueId}`
                                                                        );
                                                                    }}
                                                                >
                                                                    Details
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        obj.props.history.push(
                                                                            `/admin/company/edit/${value.uniqueId}`
                                                                        );
                                                                    }}
                                                                >
                                                                    Edit
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        obj.confirmDeleteAlert(`${value.uniqueId}`);
                                                                    }}
                                                                >
                                                                    Delete
                                                                </DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default ProjectDetails;
