/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import MappingStudentList from "../student/studentParent/MappingStudentList";
import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Row,
} from "reactstrap";
import ParentService from "../../../services/parentService";

class ParentDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parentData: {},
    };
  }

  componentDidMount() {
    const { uniqueId } = this.props.match.params;
    ParentService.fetch(uniqueId).then((response) => {
      console.log(response.data);
      this.setState({ parentData: response.data });
    });
  }

  render() {
    const { parentData } = this.state;
    return (
      <>
        <SimpleHeader
          name="Details"
          parentName="Parent"
          heading="ClashMate"
          newBtnUrl="/admin/parent/create"
          newBtnText="Create Parent"
        />
        <Container className="mt--6" fluid>
          <Card className="mb-4">
            <CardHeader>
              <h3 className="mb-0">{parentData.fullName}</h3>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      First Name
                    </label>
                    <p>{parentData.firstName}</p>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Middle Name
                    </label>
                    <p>
                      {parentData.middleName ? parentData.middleName : "N/A"}
                    </p>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols3Input"
                    >
                      Last Name
                    </label>
                    <p>{parentData.lastName}</p>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4" sm="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example4cols1Input"
                    >
                      Email Address
                    </label>
                    <p>{parentData.emailAddress}</p>
                  </FormGroup>
                </Col>
                <Col md="4" sm="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example4cols2Input"
                    >
                      Date Created
                    </label>
                    <p>{parentData.dateCreated}</p>
                  </FormGroup>
                </Col>
                <Col md="4" sm="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example4cols3Input"
                    >
                      Date of Birth
                    </label>
                    <p>{parentData.dateOfBirth}</p>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
        <br />
        <br />
        <br />
        <br />
        <MappingStudentList
          uniqueId={this.props.match.params.uniqueId}
          history={this.props.history}
        />
      </>
    );
  }
}

export default ParentDetails;
