/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {Button, Card, CardBody, CardHeader, Container, Form} from "reactstrap";
import ContactService from "../../../services/contactService";
import ContactForm from "./Form";
import {contactRole} from "../../../util/contactRole";
import _ from "lodash";
import CompanyService   from "../../../services/companyService";
import UtilService from "../../../services/utilService";

class ContactCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorData: {},
            selectKey: UtilService.uuidv4()
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleContactRoleChange = this.handleContactRoleChange.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
    }

    async componentDidMount() {
        const response = await CompanyService.list()
        console.log(response.data);
        this.setState({allCompanies: response});
        this.setState({selectKey: UtilService.uuidv4()});
        console.log(this.state);
    }



    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
        console.log(this.state);
    }



    handleSubmit(event) {
        event.preventDefault();
        ContactService.create(this.state).then(response => {
            console.log(response.data);
            if (response.responseStatus === "SUCCESS") {
                this.props.history.push("/admin/contact/list");
            } else {
                this.setState({errorData: response.data});
                console.error("Error    ==>     " + response.message);
            }
        });
    }


    handleContactRoleChange(valueObj) {
        if (_.isNull(valueObj)) {
            this.setState({selectedContactRole: {}, category: ""});
        } else {
            this.setState({selectedContactRole: valueObj, contactRole: valueObj.key});
        }
    }

    handleCompanyChange = (valueObj, event) => {
        console.log(valueObj);
        if (_.isNull(valueObj)) {
            this.setState({selectedCompany: {}, companyId :""});
        } else {
            this.setState({selectedCompany: valueObj, companyId : valueObj.uniqueId});
        }
    };

    render() {
        return (
            <>
                <SimpleHeader
                    name="Create"
                    parentName="Contact"
                    heading="XareGrowth"
                    newBtnUrl="/admin/contact/list"
                    newBtnText="List Contacts"
                />
                <Container className="mt--6" fluid>
                    <Card className="mb-4">
                        <CardHeader>
                            <h3 className="mb-0">Create Contact</h3>
                        </CardHeader>
                        <CardBody>
                            <Form role="form" onSubmit={this.handleSubmit}>
                                <ContactForm
                                    selectKey={this.state.selectKey}
                                    onChange={this.handleChange}
                                    valueObj={this.state}
                                    errorObj={this.state.errorData}
                                    isEdit={false}
                                    onContactRoleChange={this.handleContactRoleChange}
                                    selectedContact={this.state.selectedContactRole}
                                    contactRoles={contactRole}
                                    onCompanyChange={this.handleCompanyChange}
                                    selectedCompany={this.state.selectedCompany}
                                    companies={this.state.allCompanies}

                                />
                                <div className="text-center">
                                    <Button className="my-4" color="info" type="submit">
                                        Submit
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
            </>
        );
    }
}

export default ContactCreate;
