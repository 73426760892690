import React from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
  UncontrolledDropdown,
} from "reactstrap";

const Messages = ({ messageList, history, ...props }) => {
  return (
    <Table className="align-items-center table-flush" responsive>
      <thead className="thead-light">
        <tr>
          <th width={20} className="sort" data-sort="name" scope="col">
            #
          </th>
          <th className="sort" data-sort="status" scope="col">
            Message Key
          </th>
          <th className="sort" data-sort="status" scope="col">
            Message Content
          </th>
          <th className="sort" data-sort="status" scope="col">
            Message Type
          </th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody className="list">
        {messageList.map(function (value, index) {
          return (
            <tr key={index}>
              <td style={{ maxWidth: "5%" }} className="budget">
                {index + 1}
              </td>
              <td style={{ maxWidth: "25%" }} className="budget">
                {value.key}
              </td>
              <td style={{ maxWidth: "50%" }} className="budget">
                {value.content}
              </td>
              <td style={{ maxWidth: "15%" }} className="budget">
                {value.messageType}
              </td>
              <td style={{ maxWidth: "5%" }} className="text-right">
                <UncontrolledDropdown>
                  <DropdownToggle
                    color=""
                    size="sm"
                    className="btn-icon-only text-light"
                  >
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(`/admin/message/show/${value.uniqueId}`);
                      }}
                    >
                      Details
                    </DropdownItem>
                    <DropdownItem
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(`/admin/message/edit/${value.uniqueId}`);
                      }}
                    >
                      Edit
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default Messages;
