/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// core styles
import "assets/css/util.css";
import "assets/scss/argon-dashboard-pro-react.scss?v1.0.0";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/quill/dist/quill.core.css";
import "assets/vendor/select2/dist/css/select2.min.css";
import "assets/vendor/sweetalert2/dist/sweetalert2.min.css";
import AdminLayout from "layouts/Admin.jsx";
import AuthLayout from "layouts/Auth.jsx";
import ParentLayout from "layouts/Parent.jsx";
import StudentLayout from "layouts/Student.jsx";
import SuperAdminLayout from "layouts/SuperAdmin.jsx";
import TeacherLayout from "layouts/Teacher.jsx";
import React from "react";
import ReactDOM from "react-dom";
// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import {Provider} from "react-redux";
// react library for routing
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import IndexView from "views/Index.jsx";
import ProtectedRoute from "./components/auth/ProtectedRoute.jsx";
import "./i18n";
import StatusPageLayout from "./layouts/Status.jsx";
import store from "./store/configureStore";
import {AdminPermissionType} from "./util/PermissionConstant.js";
import xareGrowthProtectedRoutes from "./xareGrowthProtectedRoutes";
import KnowledgeCentre from "./views/KnowledgeCentre";

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<Switch>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN","ROLE_LEVEL1","ROLE_LEVEL2","ROLE_LEVEL3"]}
					redirectToPath="/auth/login"
					path="/admin/dashboard"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/list"
					requiredPermissionList={[AdminPermissionType.LIST_ADMIN]}
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/create"
					requiredPermissionList={[AdminPermissionType.CREATE_ADMIN]}
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/show/:uniqueId"
					requiredPermissionList={[AdminPermissionType.READ_ADMIN]}
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/edit/:uniqueId"
					requiredPermissionList={[AdminPermissionType.UPDATE_ADMIN]}
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/subject/list"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/subject/create"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/subject/show/:uniqueId"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/subject/edit/:uniqueId"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/course/list"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/course/create"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/course/show/:uniqueId"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/course/edit/:uniqueId"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN"]}
					redirectToPath="/auth/login"
					path="/superAdmin/dashboard"
					component={(props) => <SuperAdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN"]}
					redirectToPath="/auth/login"
					path="/superAdmin/tenant/list"
					component={(props) => <SuperAdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN"]}
					redirectToPath="/auth/login"
					path="/superAdmin/tenant/admin/create/:tenantUID"
					component={(props) => <SuperAdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN"]}
					redirectToPath="/auth/login"
					path="/superAdmin/tenant/configuration/:tenantUID"
					component={(props) => <SuperAdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN"]}
					redirectToPath="/auth/login"
					path="/superAdmin/tenant/admin/show/:adminUID"
					component={(props) => <SuperAdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN"]}
					redirectToPath="/auth/login"
					path="/superAdmin/tenant/create"
					component={(props) => <SuperAdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN"]}
					redirectToPath="/auth/login"
					path="/superAdmin/tenant/show/:uniqueId"
					component={(props) => <SuperAdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN"]}
					redirectToPath="/auth/login"
					path="/superAdmin/tenant/edit/:uniqueId"
					component={(props) => <SuperAdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/teacher/list"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/teacher/create"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/teacher/show/:uniqueId"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/teacher/edit/:uniqueId"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/dashboard"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/student/list"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/student/create"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/student/show/:uniqueId"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/student/edit/:uniqueId"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/parent/list"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/parent/create"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/parent/show/:uniqueId"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/parent/edit/:uniqueId"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/curriculumYear/list"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/curriculumYear/create"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/curriculumYear/show/:uniqueId"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/curriculumYear/edit/:uniqueId"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/message/list"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/message/create"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/message/show/:uniqueId"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/message/edit/:uniqueId"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/studentParent/assign/parent/:uniqueId"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/studentParent/assign/student/:uniqueId"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/section/list"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/section/create"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/section/show/:uniqueId"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/section/edit/:uniqueId"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/courseSection/assign/section/:uniqueId"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/courseSection/assign/course/:uniqueId"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/courseSectionSubject/list"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/courseSectionSubject/assign"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/courseSectionSubjectStudent/assign"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/courseSectionSubjectStudent/list"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/courseSectionSubjectTeacher/list"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/courseSectionSubjectTeacher/assign"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_STUDENT"]}
					redirectToPath="/auth/login"
					path="/student/dashboard"
					component={(props) => <StudentLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_PARENT"]}
					redirectToPath="/auth/login"
					path="/parent/dashboard"
					component={(props) => <ParentLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/question/list"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/question/create"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/question/show/:uniqueId"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/examination/list"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/examination/create"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/examination/show/:uniqueId"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/onlineExamination/create/:examinationUID"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/onlineExamination/list/:examinationUID"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/onlineExamination/show/:examinationUID/:uniqueId"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_STUDENT"]}
					redirectToPath="/auth/login"
					path="/student/onlineExamination/list"
					component={(props) => <StudentLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_STUDENT"]}
					redirectToPath="/auth/login"
					path="/student/examination/instructionPage/:examinationUID/:uniqueId"
					component={(props) => <StudentLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_STUDENT"]}
					redirectToPath="/auth/login"
					path="/student/onlineExamination/result/:onlineExaminationUID"
					component={(props) => <StudentLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_STUDENT"]}
					redirectToPath="/auth/login"
					path="/student/onlineExamination/start/:uniqueId"
					component={(props) => <StudentLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_STUDENT"]}
					redirectToPath="/auth/login"
					path="/student/onlineExamination/finish/:uniqueId"
					component={(props) => <StudentLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/tenantSetting/list"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/lead/list"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/lead/create"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/lead/show/:uniqueId"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/lead/edit/:uniqueId"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/timeTable/assign"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/timeTable/list"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/ledger/list"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/ledger/create"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/ledger/show/:uniqueId"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_PARENT"]}
					redirectToPath="/auth/login"
					path="/parent/ledger/show/:uniqueId"
					component={(props) => <ParentLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_STUDENT"]}
					redirectToPath="/auth/login"
					path="/student/ledger/show/:uniqueId"
					component={(props) => <StudentLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/ledger/show/:uniqueId"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_PARENT"]}
					redirectToPath="/auth/login"
					path="/parent/ledger/list"
					component={(props) => <ParentLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_STUDENT"]}
					redirectToPath="/auth/login"
					path="/student/ledger/list"
					component={(props) => <StudentLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/ledger/list"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_STUDENT"]}
					redirectToPath="/auth/login"
					path="/student/timeTable"
					component={(props) => <StudentLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/timeTable"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/attendance"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TENANT_ADMIN", "ROLE_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/attendance"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/attendance/assign"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_STUDENT"]}
					redirectToPath="/auth/login"
					path="/student/attendance"
					component={(props) => <StudentLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_PARENT"]}
					redirectToPath="/auth/login"
					path="/parent/attendance"
					component={(props) => <ParentLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/profile"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_STUDENT"]}
					redirectToPath="/auth/login"
					path="/student/profile"
					component={(props) => <StudentLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_PARENT"]}
					redirectToPath="/auth/login"
					path="/parent/profile"
					component={(props) => <ParentLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/settings"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_STUDENT"]}
					redirectToPath="/auth/login"
					path="/student/settings"
					component={(props) => <StudentLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_PARENT"]}
					redirectToPath="/auth/login"
					path="/parent/settings"
					component={(props) => <ParentLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/settings"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/lessonPlan/create"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/lessonPlan/list"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/meeting/list"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/meeting/create"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/meeting/show/:uniqueId"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/meeting/start/:uniqueId"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/lessonPlan/show/:uniqueId"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/lessonPlan/edit/:uniqueId"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_STUDENT"]}
					redirectToPath="/auth/login"
					path="/student/lessonPlan/show/:uniqueId"
					component={(props) => <StudentLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_STUDENT"]}
					redirectToPath="/auth/login"
					path="/student/lessonPlan/list"
					component={(props) => <StudentLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_STUDENT"]}
					redirectToPath="/auth/login"
					path="/student/meeting/show/:uniqueId"
					component={(props) => <StudentLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_STUDENT"]}
					redirectToPath="/auth/login"
					path="/student/meeting/start/:uniqueId"
					component={(props) => <StudentLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_STUDENT"]}
					redirectToPath="/auth/login"
					path="/student/meeting/list"
					component={(props) => <StudentLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/post/create"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/post/feeds"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/post/show/:uniqueId"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_STUDENT"]}
					redirectToPath="/auth/login"
					path="/student/post/create"
					component={(props) => <StudentLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_STUDENT"]}
					redirectToPath="/auth/login"
					path="/student/post/feeds"
					component={(props) => <StudentLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_STUDENT"]}
					redirectToPath="/auth/login"
					path="/student/post/show/:uniqueId"
					component={(props) => <StudentLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_STUDENT"]}
					redirectToPath="/auth/login"
					path="/student/post/edit/:uniqueId"
					component={(props) => <StudentLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/post/edit/:uniqueId"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/notice/create/"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/notices/edit/:uniqueId"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/notices/show/:uniqueId"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/notice/"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_STUDENT"]}
					redirectToPath="/auth/login"
					path="/student/notices/show/:uniqueId"
					component={(props) => <StudentLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_STUDENT"]}
					redirectToPath="/auth/login"
					path="/student/notice/"
					component={(props) => <StudentLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/notices/show/:uniqueId"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/notice/"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/notification/list"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/notification/show/:uniqueId"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/notification/edit/:uniqueId"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/notificationQueue/list"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/notificationQueue/show/:uniqueId"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_STUDENT"]}
					redirectToPath="/auth/login"
					path="/student/user/notificationQueue/show/:uniqueId"
					component={(props) => <StudentLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_STUDENT"]}
					redirectToPath="/auth/login"
					path="/student/user/notificationQueue/list"
					component={(props) => <StudentLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_PARENT"]}
					redirectToPath="/auth/login"
					path="/parent/user/notificationQueue/show/:uniqueId"
					component={(props) => <ParentLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_PARENT"]}
					redirectToPath="/auth/login"
					path="/parent/user/notificationQueue/list"
					component={(props) => <ParentLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/user/notificationQueue/show/:uniqueId"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/user/notificationQueue/list"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<Route
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/notificationQueue/list"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/notificationQueue/show/:uniqueId"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/studyMaterial/create"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/studyMaterial/list"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/studyMaterial/edit/:uniqueId"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN"]}
					redirectToPath="/auth/login"
					path="/admin/studyMaterial/show/:uniqueId"
					component={(props) => <AdminLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/studyMaterial/create"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/studyMaterial/list"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/studyMaterial/edit/:uniqueId"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/studyMaterial/show/:uniqueId"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/content/create/:uniqueId"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/content/list"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/content/edit/:uniqueId"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/content/show/:uniqueId"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_TEACHER"]}
					redirectToPath="/auth/login"
					path="/teacher/chat/list"
					component={(props) => <TeacherLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_STUDENT"]}
					redirectToPath="/auth/login"
					path="/student/chat/list"
					component={(props) => <StudentLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_STUDENT"]}
					redirectToPath="/auth/login"
					path="/student/marketplace"
					component={(props) => <StudentLayout {...props} />}
				/>
				<ProtectedRoute
					requiredRoleList={["ROLE_STUDENT"]}
					redirectToPath="/auth/login"
					path="/student/market/course/:uniqueId"
					component={(props) => <StudentLayout {...props} />}
				/>
				{xareGrowthProtectedRoutes.map((route) => route)}
				<Route path="/auth/login" render={(props) => <AuthLayout {...props} />}/>
				<Route
					path="/status/500"
					render={(props) => <StatusPageLayout {...props} />}
				/>
				<Route
					path="/status/404"
					render={(props) => <StatusPageLayout {...props} />}
				/>
				<Route
					path="/status/403"
					render={(props) => <StatusPageLayout {...props} />}
				/>
				<Route exact path="/" render={(props) => <IndexView {...props} />}/>
				<Route exact path="/knowledgeCentre" render={(props) => <KnowledgeCentre {...props} />}/>
				<Redirect from="*" to="/status/404"/>
			</Switch>
		</BrowserRouter>
	</Provider>,
	document.getElementById("root")
);
