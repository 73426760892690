/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {
  Card,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import FormInputSelect from "../../../components/common/form/FormInputSelect";
import NotificationService from "../../../services/notificationService";
import { NotificationType } from "../../../util/notificationConstant";

class NotificationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationList: [],
      notificationType: "EMAIL",
    };
    this.notificationTypeChange = this.notificationTypeChange.bind(this);
    this.fetchNotificationList = this.fetchNotificationList.bind(this);
  }

  componentDidMount() {
    this.fetchNotificationList("EMAIL");
  }

  fetchNotificationList(notificationType) {
    NotificationService.list(notificationType).then((response) => {
      this.setState({ notificationList: response.data });
    });
  }

  notificationTypeChange(event) {
    const notificationType = event.target.value;
    this.setState({ notificationType: notificationType });
    this.fetchNotificationList(notificationType);
  }

  render() {
    let obj = this;
    return (
      <>
        <SimpleHeader
          name="List"
          parentName="Notification"
          heading="ClashMate"
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col xl="12">
              <Card>
                <CardHeader className="border-0">
                  <Row>
                    <Col md="8">
                      <h3 className="mb-0">Notification List</h3>
                    </Col>
                    <Col md="4">
                      <FormInputSelect
                        name="examinationType"
                        className="form-control-label"
                        id="examinationType"
                        onChange={this.notificationTypeChange}
                        optionList={NotificationType}
                        optionKey="key"
                        optionValue="value"
                        inputClassName="form-control-sm"
                        isLabelShown={false}
                        value={this.state.notificationType}
                      />
                    </Col>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th className="sort" data-sort="name" scope="col">
                        #
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Event
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Enabled?
                      </th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody className="list">
                    {this.state.notificationList.map(function (value, index) {
                      return (
                        <tr key={index}>
                          <td className="budget">{index + 1}</td>
                          <td className="budget">{value.notificationEvent}</td>
                          <td className="budget">
                            {value.isEnabled === true ? "Yes" : "No"}
                          </td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                color=""
                                size="sm"
                                className="btn-icon-only text-light"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  onClick={(e) => {
                                    e.preventDefault();
                                    obj.props.history.push(
                                      `/admin/notification/show/${value.uniqueId}`
                                    );
                                  }}
                                >
                                  Details
                                </DropdownItem>
                                <DropdownItem
                                  onClick={(e) => {
                                    e.preventDefault();
                                    obj.props.history.push(
                                      `/admin/notification/edit/${value.uniqueId}`
                                    );
                                  }}
                                >
                                  Edit
                                </DropdownItem>
                                <DropdownItem
                                  onClick={(e) => {
                                    e.preventDefault();
                                    obj.delete(`${value.uniqueId}`);
                                  }}
                                >
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default NotificationList;
